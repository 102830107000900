import { Legend, LegendItemType } from '../types';

const legends: Legend[] = [
  {
    key: 'water-quality-locations',
    title: 'legends.waterQualityLocations.title',
    legendItems: [
      {
        type: LegendItemType.RECTANGLE,
        color: '#67ac44',
        label: 'legends.waterQualityLocations.legendItems.noExeedance',
      },
      {
        type: LegendItemType.RECTANGLE,
        color: '#c30000',
        label: 'legends.waterQualityLocations.legendItems.exceedance',
      },
      {
        type: LegendItemType.RECTANGLE,
        color: '#d4d4d4',
        label: 'legends.waterQualityLocations.legendItems.noData',
      },
    ],
  },
  {
    key: 'substance-analysis',
    title: 'legends.substanceAnalysisLocations.title',
    legendItems: [
      {
        type: LegendItemType.RECTANGLE,
        color: '#D4D4D4',
        label: 'legends.substanceAnalysisLocations.legendItems.noData',
      },
      {
        type: LegendItemType.RECTANGLE,
        color: '#FFF',
        label: 'legends.substanceAnalysisLocations.legendItems.noEvaluation',
      },
      {
        type: LegendItemType.RECTANGLE,
        color: '#67AC44',
        label: 'legends.substanceAnalysisLocations.legendItems.noExeedance',
      },
      {
        type: LegendItemType.RECTANGLE,
        color: 'yellow',
        label: 'legends.substanceAnalysisLocations.legendItems.below10Percent',
      },
      {
        type: LegendItemType.RECTANGLE,
        color: 'orange',
        label: 'legends.substanceAnalysisLocations.legendItems.below20Percent',
      },
      {
        type: LegendItemType.RECTANGLE,
        color: 'purple',
        label: 'legends.substanceAnalysisLocations.legendItems.above20Percent',
      },
    ],
  },
];

export default legends;
