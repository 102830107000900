import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { store } from '../../store';
import { load } from '../../redux/init/actions';
import HeaderContainer from './../header.container';
import SubstanceStatusContainer from './../substance-status.container';
import GeneralStatusContainer from './../general-status.container';
import MapContainer from './../map.container';
import Sidebar from '../../components/sidebar';
import { RootState } from '../../types';
import styles from './styles.module.scss';
import LoginContainer from '../login.container/login.container';
import Navigation from '../../components/navigation/navigation.component';
import UploadDataContainer from '../upload-data.container/upload-data.container';
import BmpInfoContainer from '../../components/bmp-info/bmp-info.container';
import { Preloader, LoginRoute, PrivateRoute } from 'marvin-auth-kit';

interface Props {}

const AppContainer = (props: Props) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const dispatch = useDispatch();
  const isMaximized = useSelector(
    (rootState: RootState) => rootState.preferences.isMaximized
  );

  useEffect(() => {
    async function loadInitialData() {
      persistStore(store, {}, async () => {
        try {
          dispatch(load()).then(() => {
            setIsInitialized(true);
          });
        } catch (ex) {
          setError(ex);
          setIsError(true);
        }
      });
    }
    loadInitialData();
  }, [dispatch]);

  const renderError = () => {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>
          Error while loading...
          <div className={styles.errorDetail}>
            {error !== null && (error! as Error).toString()}
          </div>
        </div>
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <React.Fragment>
        <HeaderContainer />
        <div className="main-container">
          {!isMaximized && <Navigation />}
          <Switch>
            <LoginRoute
              redirectTo="/upload-data"
              path="/admin"
              component={LoginContainer}
            />
            <PrivateRoute
              redirectTo="/admin"
              path="/upload-data"
              component={UploadDataContainer}
            />
            <Route path="/measures/:bmpId" exact component={BmpInfoContainer} />
            <Route path="/" component={MapContainer} />
          </Switch>

          <Sidebar>
            <Switch>
              <Route
                path="/water-quality-locations/:locationId"
                exact={true}
                component={GeneralStatusContainer}
              />
              <Route
                path="/substance-analysis/limit/:limit/substance/:substanceId/:locationId"
                exact={true}
                component={SubstanceStatusContainer}
              />
            </Switch>
          </Sidebar>
        </div>
      </React.Fragment>
    );
  };

  return (
    <Preloader isLoading={!isInitialized}>
      <React.Fragment>
        {isError && renderError()}
        {isInitialized && renderSuccess()}
      </React.Fragment>
    </Preloader>
  );
};

export default AppContainer;
