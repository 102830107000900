import { constants, InitState } from './types';
import { ReduxAction } from '../../types';

const initialState: InitState = {
  isLoading: true,
  isError: false,
  errorMessage: '',
};

const init = (state = initialState, action: ReduxAction): InitState => {
  switch (action.type) {
    case constants.INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMessage: '',
      };

    case constants.INIT_FULFILLED:
      return {
        ...state,
        isLoading: false,
      };

    case constants.INIT_REJECTED:
      return {
        ...state,
        isError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default init;
