import { ReduxAction, Period, Catchment, Source } from '../../types';
import constants from './constants';
import * as catchmentService from '../../services/catchment.service';

export function loadCatchmentDates(
  catchment: Catchment,
  source: Source
): ReduxAction {
  return {
    type: constants.LOAD_CATCHMENT_ACTIVE_DATES,
    payload: catchmentService.getActiveDates(catchment, source),
  };
}

export function activatePeriod(period: Period): ReduxAction {
  return {
    type: constants.SET_ACTIVE_PERIOD,
    payload: period,
  };
}
