import axios from 'axios';
import { MyMapClick } from '../types';
import { FeatureCollection } from 'geojson';

export async function getFeatureInfo(
  layer: any,
  event: MyMapClick
): Promise<FeatureCollection | undefined> {
  const { x, y, bbox, mapSize } = event;
  const config = {
    params: {
      version: '1.1.0',
      request: 'GetFeatureInfo',
      service: 'WMS',
      srs: 'EPSG:4326',
      layers: layer.wmsLayer,
      query_layers: layer.wmsLayer,
      x: Math.round(x), // ensure only integers as value
      y: Math.round(y), // ensure only integers as value
      bbox,
      width: mapSize.x,
      height: mapSize.y,
      info_format: 'application/json',
    },
  };

  try {
    const { data } = await axios.get(`/geoserver/waterprotecteu/wms`, config);
    return data;
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
}
