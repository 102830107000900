import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import { withTranslation, WithTranslation } from 'react-i18next';
import { LatLngBoundsExpression } from 'leaflet';
import { RouteComponentProps } from 'react-router';

import { getActiveBaseLayer, getActiveCatchment } from '../../selectors';
import Map from '../../components-marvin/map';
import Tooltip from '../../components-marvin/tooltip';
import Window from '../../components-marvin/window';
import {
  MapControl,
  MapControlButton,
  MapControlGroup,
} from '../../components-marvin/map-controls';
import Icon from '../../components-marvin/icon';
import Legend from '../../components-marvin/legend';
import { setMapPosition, setSelectedParcelUidn } from '../../redux/map/actions';
import * as PreferencesActions from '../../redux/preferences/actions';
import * as CatchmentActions from '../../redux/catchments/actions';
import WQLOnMapContainer from '../on-map/wql-on-map.container';
import SubstancesOnMapContainer from '../on-map/substances-on-map.container';
import {
  Catchment,
  CatchmentLayer,
  Legend as ILegend,
  MapPosition,
  RootState,
  LeafletLayer,
  MyMapClick,
} from '../../types';
import styles from './styles.module.scss';
import * as legendUtils from '../../utils/legend.utils';
import LayerToggle from '../../components/layer-toggle/layer-toggle.component';
import ParcelLayers from '../../components/parcel-layers/parcel-layers.component';
import { getFeatureInfo } from '../../selectors/ogc.service';

interface OwnProps {
  position: MapPosition;
  boundingbox: LatLngBoundsExpression | null;
  layerOpacity: number;
  isMaximized: boolean;
  activeBaseLayer: any; // TODO: refactor into type
  activeCatchment: Catchment | undefined;
  activeParcelLayerId: number | null;
}

type Props = OwnProps & WithTranslation & RouteComponentProps & DispatchProp;

interface State {
  position: MapPosition;
  isLegendVisible: boolean;
  isLayersVisible: boolean;
  geoJSONs: any;
}

class MapContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      position: this.props.position,
      isLegendVisible: false,
      isLayersVisible: true,
      geoJSONs: [],
    };
  }
  componentDidUpdate(prevProps: Props) {
    if (
      this.props.location !== prevProps.location &&
      this.props.activeCatchment !== undefined
    ) {
      this.props.dispatch(setSelectedParcelUidn(0));
      const layers = this.props.activeCatchment.layers.filter(
        (layer: CatchmentLayer) => layer.parcelMap && layer.isActive
      );
      if (layers && layers[0]) {
        // this.handleParcelLayerClick(this.props.activeParcelLayerId || 0, true);
        this.setState({ ...this.state, geoJSONs: [] });
      }
    }
    if (prevProps.activeParcelLayerId !== this.props.activeParcelLayerId) {
      this.setState({ ...this.state, geoJSONs: [] });
    }
    const { position } = this.props;
    if (!isEqual(position, prevProps.position)) {
      this.setState({
        ...this.state,
        position,
      });
    }
  }
  updateMapPosition(position: MapPosition) {
    this.setState({ position });
    this.props.dispatch(setMapPosition(position));
  }

  handleMapZoom(direction: 'in' | 'out') {
    const { zoom } = this.state.position;
    if (direction === 'in') {
      this.setState({ position: { ...this.state.position, zoom: zoom + 1 } });
    }
    if (direction === 'out') {
      if (zoom === 0) return;
      this.setState({ position: { ...this.state.position, zoom: zoom - 1 } });
    }
  }

  handleMaximizeToggle() {
    this.props.dispatch(
      PreferencesActions.setIsMaximized(!this.props.isMaximized)
    );
  }

  handleToggleLegend() {
    this.setState({
      isLegendVisible: !this.state.isLegendVisible,
    });
  }

  handleLayerClick(layerId: number) {
    if (this.props.activeCatchment === undefined) return;

    return this.props.dispatch(
      CatchmentActions.toggleActiveLayer(this.props.activeCatchment, layerId)
    );
  }

  handleParcelLayerToggle(layerId: number) {
    if (this.props.activeCatchment === undefined) return;
    return this.props.dispatch(
      CatchmentActions.setActiveParcelLayer(this.props.activeCatchment, layerId)
    );
  }

  handleToggleLayers() {
    this.setState({
      isLayersVisible: !this.state.isLayersVisible,
    });
  }

  async handleMapClick(mapClick: MyMapClick) {
    const activeLayers:
      | CatchmentLayer[]
      | undefined = this.props.activeCatchment?.layers.filter(
      layer => layer.isActive && layer.bmpMap
    );
    if (activeLayers && activeLayers[0]) {
      const featureInfo = await getFeatureInfo(activeLayers[0], mapClick);
      const uidn: number | undefined =
        featureInfo?.features[0]?.properties?.UIDN;
      if (uidn) {
        this.props.dispatch(setSelectedParcelUidn(uidn));
      }
      if (featureInfo?.features) {
        const adjustedFeature: any = featureInfo.features[0];
        this.setState({
          ...this.state,
          geoJSONs: [adjustedFeature],
        });
      }
    }
  }

  render() {
    const { zoom, lat, lng } = this.state.position;
    const baseLayers = [this.props.activeBaseLayer];
    const overlayers = getOverlayers(this.props.activeCatchment);

    return (
      <div className={styles.container}>
        <Map
          zoom={zoom}
          lat={lat}
          lng={lng}
          boundingbox={this.props.boundingbox}
          baselayers={baseLayers}
          overlayers={overlayers}
          onClick={event => {
            this.handleMapClick(event);
          }}
          geoJSONs={this.state.geoJSONs}
          onChange={this.updateMapPosition.bind(this)}
          overlayerOpacity={this.props.layerOpacity}
        >
          <MapControlGroup position="BOTTOM_RIGHT">
            <MapControlButton onClick={this.handleMapZoom.bind(this, 'in')}>
              <Icon name="plus" />
            </MapControlButton>
            <MapControlButton onClick={this.handleMapZoom.bind(this, 'out')}>
              <Icon name="minus" />
            </MapControlButton>
          </MapControlGroup>

          <Route
            exact={false}
            path="/water-quality-locations/:locationId?"
            component={WQLOnMapContainer}
          />

          <Route
            exact={false}
            path="/substance-analysis/limit/:limit?/substance/:substanceId?/:locationId?"
            component={SubstancesOnMapContainer}
          />
        </Map>
        <MapControlGroup position="TOP_RIGHT">
          {this.props.activeCatchment && this.renderLayers()}
        </MapControlGroup>

        <MapControlGroup position="BOTTOM_LEFT">
          {this.props.activeCatchment && this.renderLegend()}
        </MapControlGroup>
      </div>
    );
  }

  renderLegend() {
    if (!this.props.activeCatchment) return null;

    const { t } = this.props;

    const layers = this.props.activeCatchment.layers.filter(
      (layer: CatchmentLayer) => layer.isActive
    );
    const layerLegends = layers.map(layer => layer.legend);
    const routerLegend = getRouteLegends(this.props.location.pathname);
    const legends: ILegend[] = [...routerLegend, ...layerLegends];

    if (this.state.isLegendVisible) {
      return (
        <MapControl>
          <Window
            title={t('map.legendTitle')}
            width={245}
            onClose={this.handleToggleLegend.bind(this)}
          >
            {legends.map((legend, i) => (
              <Legend key={i} legend={legend} />
            ))}
          </Window>
        </MapControl>
      );
    } else {
      // show legend button
      return (
        <MapControlButton onClick={this.handleToggleLegend.bind(this)}>
          <Tooltip text={t('map.legendTooltip')} position="bottom" />
          <Icon name="list-alt" />
        </MapControlButton>
      );
    }
  }

  renderLayers() {
    // if no active catchment, render no layers
    if (!this.props.activeCatchment) return null;

    const { t } = this.props;

    if (this.state.isLayersVisible) {
      return (
        <MapControl>
          <Window
            title={t('map.layersTitle')}
            width={250}
            onClose={this.handleToggleLayers.bind(this)}
          >
            <h2>{t('map.generalMaps')}</h2>
            <div className={styles.layerList}>
              {this.props.activeCatchment.layers.map(
                (layer: CatchmentLayer) => {
                  if (!layer.parcelMap) {
                    return (
                      <LayerToggle
                        key={layer.id}
                        layer={layer}
                        onClick={(id: number) => {
                          this.handleLayerClick(id);
                        }}
                      />
                    );
                  }
                  return null;
                }
              )}
            </div>
            <h2>{t('map.parcelMaps')}</h2>
            <ParcelLayers
              layers={this.props.activeCatchment.layers.filter(
                (layer: CatchmentLayer) => {
                  return layer.parcelMap;
                }
              )}
              activeLayer={this.props.activeParcelLayerId}
              onToggleLayer={(id: number) => {
                this.handleParcelLayerToggle(id);
              }}
            />
          </Window>
        </MapControl>
      );
    } else {
      // show legend button
      return (
        <MapControlButton onClick={this.handleToggleLayers.bind(this)}>
          <Tooltip text={t('map.layersTooltip')} position="left" />
          <Icon name="layers" />
        </MapControlButton>
      );
    }
  }
}

function getRouteLegends(pathname: string): ILegend[] {
  if (/\/water-quality-locations.*/.test(pathname)) {
    let legend = legendUtils.getLegend('water-quality-locations');
    if (legend) return [legend];
  }
  if (/\/substance-analysis.*/.test(pathname)) {
    let legend = legendUtils.getLegend('substance-analysis');
    if (legend) return [legend];
  }
  return [];
}
function getOverlayers(catchment: Catchment | undefined): LeafletLayer[] {
  if (!catchment) return [];

  const activeLayers = catchment.layers.filter(c => c.isActive);
  return activeLayers.map(layer => {
    return {
      url: 'geoserver//waterprotecteu/wms',
      layers: layer.wmsLayer,
      transparent: true,
      format: 'image/png',
      style: '',
      minZoom: 0,
      maxZoom: 18,
      buffer: layer.tileBuffer_px,
    };
  });
}

function mapStateToProps(state: RootState) {
  return {
    activeBaseLayer: getActiveBaseLayer(state),
    position: state.map.position,
    boundingbox: state.map.boundingbox,
    layerOpacity: state.map.layerOpacity,
    isMaximized: state.preferences.isMaximized,
    activeCatchment: getActiveCatchment(state),
    activeParcelLayerId: state.catchments.activeParcelLayerId,
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps)(MapContainer))
);
