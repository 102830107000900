import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { RootState, Catchment } from '../types';
import { getActiveCatchment } from '../selectors';
import Icon from '../components-marvin/icon';

interface OwnProps {
  activeCatchment: Catchment | undefined;
}
type Props = OwnProps & WithTranslation & DispatchProp;
interface State {}

class InformationContainer extends React.Component<Props, State> {
  render() {
    const { t } = this.props;
    return (
      <div className="information-container primary-content-container">
        <div className="primary-content-header">
          <h1 className="title">{t('about.title')}</h1>
        </div>
        <div className="primary-content-body">
          <span
            dangerouslySetInnerHTML={{
              __html: t('about.information'),
            }}
          />

          <h2 className="sub-subtitle">
            {t('about.catchmentAdministrators.title')}
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: t('about.catchmentAdministrators.body'),
            }}
          />

          {this.props.activeCatchment!.administrators.map(administrator => {
            return (
              <div key={administrator} className="administrator">
                <Icon
                  name="envelope"
                  fill="#33A3DC"
                  size={17}
                  className="icon"
                />
                <a href={`mailto:${administrator}`}>{administrator}</a>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    activeCatchment: getActiveCatchment(state),
  };
}

export default withTranslation()(
  connect(mapStateToProps)(InformationContainer)
);
