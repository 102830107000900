import { ReduxAction, RootState, Catchment, Source } from '../../types';
import * as catchmentService from '../../services/catchment.service';
import { setMapPosition } from './../map/actions';
import { loadCatchmentDates } from './../period/actions';
import { getActiveCatchment } from './../../selectors';
import { Dispatch } from 'redux';
import {
  SET_ACTIVE_CATCHMENT,
  LOAD_CATCHMENTS,
  TOGGLE_ACTIVE_LAYER,
  SET_ACTIVE_PARCEL_LAYER,
} from './types';

// @ts-ignore
const dataLayer = window.dataLayer;

export function getCatchments(): ReduxAction {
  return {
    type: LOAD_CATCHMENTS,
    payload: catchmentService.getAll(),
  };
}

export function activateCatchment(catchment: Catchment, source: Source): any {
  dataLayer.push({ actionLab: catchment.name });

  return (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(setActiveCatchment(catchment, source));
    dispatch(loadCatchmentDates(catchment, source));
    navigateToActiveCatchment(dispatch, getState());
  };
}

function navigateToActiveCatchment(dispatch: Dispatch, state: RootState) {
  const catchment = getActiveCatchment(state);
  if (catchment === undefined) return;
  const mapPosition = {
    lat: catchment.location_4326.coordinates['1'],
    lng: catchment.location_4326.coordinates['0'],
    zoom: catchment.zoomFactor,
  };
  dispatch(setMapPosition(mapPosition));
}

function setActiveCatchment(catchment: Catchment, source: Source): ReduxAction {
  return {
    type: SET_ACTIVE_CATCHMENT,
    payload: {
      catchment,
      source,
    },
  };
}

export function toggleActiveLayer(
  catchment: Catchment,
  layerId: number
): ReduxAction {
  return {
    type: TOGGLE_ACTIVE_LAYER,
    payload: {
      catchment,
      layerId,
    },
  };
}

export function setActiveParcelLayer(
  catchment: Catchment,
  layerId: number
): ReduxAction {
  return {
    type: SET_ACTIVE_PARCEL_LAYER,
    payload: {
      catchment,
      layerId,
    },
  };
}
