import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { DispatchProp, useSelector } from 'react-redux';
// @ts-ignore: TODO: add custom module definition
import Flex, { FlexItem } from 'styled-flex-component';
import { WithTranslation, useTranslation } from 'react-i18next';
import { Period, Catchment, Source, Standard, Substance } from '../../types';
import * as StandardService from '../../services/standard.service';
import * as SubstanceService from '../../services/substance.service';
import RadioButton from '../../components-marvin/radio-button/radio-button';
import {
  PrimaryContentContainer,
  PrimaryContentHeader,
  PrimaryContentBody,
} from '../../components/primary-content';
import { Heading } from '../../components/design-system';

import styles from './styles.module.scss';
import {
  getActiveCatchment,
  getActiveSource,
  getActivePeriod,
} from '../../selectors';
import { RouteComponentProps } from 'react-router';
import { ParameterPane } from './parameter-pane';
import { NitratePane } from './nitrate-pane';
import { PesticidePane } from './pesticide-pane';
import { PesticideSumPane } from './pesticide-sum-pane';

interface OwnProps {
  activeCatchment: Catchment | undefined;
  activeSource: Source | undefined;
  activePeriod: Period;
}

type Props = OwnProps &
  WithTranslation &
  RouteComponentProps<{ substanceId: any }> &
  DispatchProp;

enum AnalysisType {
  'Parameters' = 'Parameters',
  'Nitrates' = 'Nitrates',
  'Pesticides' = 'Pesticides',
  'PesticideSum' = 'PesticideSum',
}

const SubstanceAnalysisContainer = (props: Props) => {
  const { t } = useTranslation();

  const activeCatchment = useSelector(getActiveCatchment);
  const activeSource = useSelector(getActiveSource);
  const activePeriod = useSelector(getActivePeriod);

  const [activeType, setActiveType] = useState<AnalysisType>(
    AnalysisType.Parameters
  );

  const [standards, setStandards] = useState<Standard[]>([]);
  const [parameterList, setParameterList] = useState<any>([]);
  const [pesticideList, setPesticideList] = useState<any>([]);
  const [pesticideSumList, setPesticideSumList] = useState<any>([]);
  const [nitrateList, setNitrateList] = useState<any>([]);

  useEffect(() => {
    async function loadDataLists() {
      if (activeCatchment === undefined || activeSource === undefined) return;
      const dataLists = await SubstanceService.getSubstancesAll(
        activeCatchment.id,
        activeSource.key,
        activePeriod
      );

      setParameterList(dataLists.parameterList);
      setPesticideList(dataLists.pesticideList);
      setPesticideSumList(dataLists.pesticideSumList);
      setNitrateList(dataLists.nitrateList);
    }
    loadDataLists();
  }, [activeCatchment, activePeriod, activeSource]);

  useEffect(() => {
    async function loadStandards() {
      if (activeSource === undefined) return;
      const standards = await StandardService.getStandards(activeSource.key);
      setStandards(standards);
    }
    loadStandards();
  }, [activeSource]);

  function handleSubmitParametersType(substance: Substance) {
    props.history.push(
      `/substance-analysis/limit/parameter/substance/${substance.id}`
    );
  }

  function handleSubmitNitrateType(substance: Substance, standard: Standard) {
    props.history.push(
      `/substance-analysis/limit/${standard.type.key}/substance/${substance.id}`
    );
  }

  function handleSubmitPesticideType(substance: Substance, standard: Standard) {
    props.history.push(
      `/substance-analysis/limit/${standard.type.key}/substance/${substance.id}`
    );
  }

  // render the component
  return render();

  function render() {
    return (
      <PrimaryContentContainer>
        <PrimaryContentHeader>
          <Heading.h1>{t('substanceAnalysis.title')}</Heading.h1>
        </PrimaryContentHeader>
        <PrimaryContentBody>
          <Flex full column justifyBetween>
            <FlexItem>
              <Heading.h2 className={styles.h2}>
                {t('substanceAnalysis.titleVisualisation')}
              </Heading.h2>

              {Object.keys(AnalysisType).map((type: string) => {
                return (
                  <div className={styles.radioButton} key={type}>
                    <RadioButton
                      onClick={() => setActiveType(type as AnalysisType)}
                      active={activeType === type}
                    />
                    <span>{t(`substanceAnalysis.title${type}`)}</span>
                  </div>
                );
              })}

              {renderChoosenTypePane(activeType)}
            </FlexItem>
          </Flex>
        </PrimaryContentBody>
      </PrimaryContentContainer>
    );
  }

  function renderChoosenTypePane(selectedType: string) {
    switch (selectedType) {
      case 'Parameters':
        return (
          <ParameterPane
            parameters={parameterList}
            onSubmit={handleSubmitParametersType}
          />
        );
      case 'Nitrates':
        return (
          <NitratePane
            nitrates={nitrateList}
            standards={standards}
            onSubmit={handleSubmitNitrateType}
          />
        );
      case 'Pesticides':
        return (
          <PesticidePane
            pesticides={pesticideList}
            standards={standards}
            onSubmit={handleSubmitPesticideType}
          />
        );
      case 'PesticideSum':
        return (
          <PesticideSumPane
            crops={pesticideSumList}
            standards={standards}
            onSubmit={handleSubmitPesticideType}
          />
        );
    }
  }
};

export default withRouter(SubstanceAnalysisContainer);
