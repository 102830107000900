import { MapPosition, BaseLayer } from '../../types';
import {
  MapActionTypes,
  SET_BASELAYER,
  SET_MAP_BOUNDINGBOX,
  SET_MAP_POSITION,
  SET_LAYER_OPACITY,
  SET_SELECTED_PARCEL_UIDN,
} from './types';
import { LatLngBoundsExpression } from 'leaflet';

export function setBaseLayer(baseLayer: BaseLayer): MapActionTypes {
  return {
    type: SET_BASELAYER,
    payload: baseLayer.key,
  };
}

export function setLayerOpacity(opacity: number): MapActionTypes {
  return {
    type: SET_LAYER_OPACITY,
    payload: opacity,
  };
}

export function setMapPosition(mapPosition: MapPosition): MapActionTypes {
  return {
    type: SET_MAP_POSITION,
    payload: mapPosition,
  };
}

export function setMapBoundingbox(
  bounds: LatLngBoundsExpression
): MapActionTypes {
  return {
    type: SET_MAP_BOUNDINGBOX,
    payload: bounds,
  };
}

export function setSelectedParcelUidn(parcelUidn: number): MapActionTypes {
  return {
    type: SET_SELECTED_PARCEL_UIDN,
    payload: parcelUidn,
  };
}
