import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { BaseLayer, RootState } from '../types';

import InputRange from './../components-marvin/input-range';
import { baseLayers } from '../config';
import { getActiveBaseLayer } from '../selectors';
import * as mapActions from '../redux/map/actions';
import BackgroundMapListItem from '../components-marvin/background-map-list-item';
import { withTranslation, WithTranslation } from 'react-i18next';

interface OwnProps {
  layerOpacity: number;
  activeBaseLayer: BaseLayer;
}

type Props = OwnProps & WithTranslation & DispatchProp;

class ConfigurationContainer extends React.Component<Props, {}> {
  handleBaseLayerChange(baseLayer: BaseLayer) {
    this.props.dispatch(mapActions.setBaseLayer(baseLayer));
  }

  handleOpacityChange(opacity: number) {
    this.props.dispatch(mapActions.setLayerOpacity(opacity / 100));
  }

  render() {
    const { t } = this.props;

    return (
      <div className="primary-content-container">
        <div className="primary-content-header">
          <h1 className="title">{t('configuration.title')}</h1>
        </div>

        <div className="primary-content-body">
          <div className="subtitle">{t('configuration.titleTransparency')}</div>
          <InputRange
            min={0}
            max={100}
            step={5}
            value={this.props.layerOpacity * 100}
            onChange={this.handleOpacityChange.bind(this)}
          />

          <div className="subtitle">
            {t('configuration.titleBackgroundMap')}
          </div>
          {baseLayers.map(baselayer => (
            <BackgroundMapListItem
              key={baselayer.key}
              previewUrl={baselayer.image}
              title={t(baselayer.translationKey)}
              isActive={baselayer.key === this.props.activeBaseLayer.key}
              onClick={this.handleBaseLayerChange.bind(this, baselayer)}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const { layerOpacity } = state.map;
  return { activeBaseLayer: getActiveBaseLayer(state), layerOpacity };
};

export default withTranslation()(
  connect(mapStateToProps)(ConfigurationContainer)
);
