import * as React from "react";
import * as PropTypes from "prop-types";
import { isEmpty, noop, truncate } from "lodash-es";
import onClickOutside from "react-onclickoutside";
import * as KeyboardEventHandler from "react-keyboard-event-handler";
import classNames from "classnames";
import Icon from "../../components-marvin/icon";
import * as NominatimService from "../../services/nominatim.service";
import styles from "./location-search-box.module.scss";

class LocationSearchBox extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onSearchResultClick: PropTypes.func,
    classname: PropTypes.string
  };

  static defaultProps = {
    placeholder: "Search a location...",
    onSearchResultClick: noop,
    classname: ""
  };

  constructor(props) {
    super(props);

    this.state = {
      searchQuery: "",
      searchTimeout: false,
      searchResults: [],
      selectedResultIndex: -1,
      isOpen: false
    };
  }

  handleClickOutside() {
    this.setState({ isOpen: false });
  }

  handleSearchQueryChange(event) {
    const { value } = event.target;
    clearTimeout(this.state.searchTimeout);
    this.setState({
      searchQuery: value,
      isOpen: true,
      selectedResultIndex: 0
    });
    if (isEmpty(value)) return;

    this.setState({
      searchTimeout: setTimeout(async () => {
        this.searchResults(value);
      }, 500)
    });
  }

  async searchResults(searchQuery) {
    const searchResults = await NominatimService.getLocation(searchQuery);
    this.setState({
      searchResults
    });
  }

  handleSearchResultClick(searchResult) {
    this.setState({
      isOpen: false,
      searchQuery: searchResult.formattedAddress
    });
    this.props.onSearchResultClick(searchResult);
  }

  handleKeyEvent(key, e) {
    e.preventDefault();
    switch (key) {
      case "down":
        this.selectNextResult();
        break;
      case "up":
        this.selectPreviousResult();
        break;
      case "enter":
        this.useSelectedResult();
        break;
      case "esc":
        this.clearSearch();
        break;
      default:
      // do nothing
    }
  }

  clearSearch() {
    this.setState({ searchQuery: "", searchResults: [] });
  }

  selectNextResult() {
    if (isEmpty(this.state.searchResults)) return;

    // is results are not visibile, only show the results without going to the next
    if (this.state.isOpen === false) {
      this.setState({ isOpen: true });
      return;
    }

    const currentIndex = this.state.selectedResultIndex;
    let nextIndex = currentIndex + 1;
    if (currentIndex + 1 === this.state.searchResults.length) nextIndex = -1;

    this.setState({ selectedResultIndex: nextIndex });
  }

  selectPreviousResult() {
    if (isEmpty(this.state.searchResults)) return;

    // is results are not visibile, only show the results without going to the next
    if (this.state.isOpen === false) {
      this.setState({ isOpen: true });
      return;
    }

    const currentIndex = this.state.selectedResultIndex;
    let previousIndex = currentIndex - 1;
    if (currentIndex - 1 < -1)
      previousIndex = this.state.searchResults.length - 1;

    this.setState({ selectedResultIndex: previousIndex });
  }

  useSelectedResult() {
    if (this.state.searchResults.length === 0) return;
    if (this.state.selectedResultIndex === -1) {
      this.setState({ selectedResultIndex: 0 });
    }
    const currentSearchResult = this.state.searchResults[
      this.state.selectedResultIndex
    ];
    this.handleSearchResultClick(currentSearchResult);
  }

  render() {
    return (
      <div className={styles.container}>
        <KeyboardEventHandler
          handleKeys={["up", "down", "enter", "esc"]}
          onKeyEvent={this.handleKeyEvent.bind(this)}
        >
          <div className={styles["search-box"]}>
            <input
              type="input"
              placeholder={this.props.placeholder}
              value={this.state.searchQuery}
              onFocus={() => this.setState({ isOpen: true })}
              onChange={this.handleSearchQueryChange.bind(this)}
            />
            {!isEmpty(this.state.searchQuery) && (
              <Icon
                name="times"
                className={styles["icon-close"]}
                onClick={this.clearSearch.bind(this)}
              />
            )}
            <Icon name="search" className={styles["icon-search"]} />
          </div>
        </KeyboardEventHandler>

        <div className={styles["search-results"]}>
          {this.state.isOpen &&
            this.state.searchResults.map((searchResult, i) => {
              const cx = classNames(styles["search-result-item"], {
                [styles.selected]: this.state.selectedResultIndex === i
              });
              return (
                <div
                  key={searchResult.id}
                  className={cx}
                  onClick={this.handleSearchResultClick.bind(
                    this,
                    searchResult
                  )}
                >
                  <Icon
                    name="map-marker-alt"
                    className={styles.searchResultIcon}
                  />
                  <div className={styles.searchResultText}>
                    {truncate(searchResult.formattedAddress, { length: 30 })}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

// export default {
//   LocationSearchBox: onClickOutside(LocationSearchBox)
// };

export default onClickOutside(LocationSearchBox);
