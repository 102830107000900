import axios from 'axios';
import * as axiosUtils from './../utils/axios.utils';
import { Catchment, Source } from '../types';

export async function getAll(): Promise<Catchment[]> {
  const headers = axiosUtils.commonHeaders();
  const response = await axios.get(`/api/catchments`, { headers });
  return response.data;
}

interface ActiveDatesPayload {
  catchment: Catchment;
  source: Source;
  dates: number[];
}

export async function getActiveDates(
  catchment: Catchment,
  source: Source,
  year = new Date().getFullYear()
): Promise<ActiveDatesPayload> {
  const headers = axiosUtils.commonHeaders();
  const response = await axios.get(
    `/api/catchments/${catchment.id}/activeDates`,
    {
      headers,
      params: {
        source: source.key,
        year,
      },
    }
  );
  return {
    catchment,
    source,
    dates: response.data,
  };
}
