import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

export const PrimaryContentContainer = (props: {
  className?: string;
  children: any;
}) => {
  return (
    <div className={cx(styles.primaryContentContainer, props.className)}>
      {props.children}
    </div>
  );
};

export const PrimaryContentHeader = (props: {
  children: any;
  className?: string;
}) => {
  return (
    <div className={cx(styles.primaryContentHeader, props.className)}>
      {props.children}
    </div>
  );
};

export const PrimaryContentBody = (props: { children: any }) => {
  return <div className={styles.primaryContentBody}>{props.children}</div>;
};

export const PrimaryContentClose = (props: {}) => {
  return <div className={styles.primaryContentClose} />;
};
