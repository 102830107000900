import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, useParams } from 'react-router';
import { Markup } from 'interweave';
import { PrimaryContentHeader } from '../primary-content';
import { Heading } from '../design-system';
import { getBmp } from '../../services/measures.service';
import { BmpInfo } from '../../types';
import Icon from '../../components-marvin/icon';
import styles from './styles.module.scss';

interface OwnProps {}

type Props = OwnProps & RouteComponentProps<{ bmpId: any }>;

const BmpInfoContainer = (props: Props) => {
  const { t } = useTranslation();
  let { bmpId }: any = useParams();
  const [bmpInfo, setBmpInfo] = useState<BmpInfo | null>(null);

  useEffect(() => {
    async function getBmpInfo() {
      setBmpInfo(await getBmp(bmpId));
    }
    getBmpInfo();
  }, [bmpId]);

  return (
    <div className={styles.bmpInfo}>
      <PrimaryContentHeader className={styles.headerTitle}>
        <Heading.h1>{t('bmpInfo.title')}</Heading.h1>{' '}
        <Icon
          name="times"
          size={20}
          className={styles.close}
          fill={'#C0C0C0'}
          onClick={event => {
            props.history.push('/measures');
          }}
        />
      </PrimaryContentHeader>
      <div className={styles.bmpInfoSection}>
        <div className={styles.main}>
          <h2>{`${bmpInfo?.number}. ${bmpInfo?.description}`}</h2>
          <Markup content={bmpInfo?.introduction} />
          <h2>{t('bmpInfo.benefitsLimitations')}</h2>
          <Markup content={bmpInfo?.benefitsLimitations} />
          <h2>{t('bmpInfo.cost')}</h2>
          <Markup content={bmpInfo?.costs} />
        </div>
        <div className={styles.meta}>
          {bmpInfo?.imageUrl ? <img alt={''} src={bmpInfo?.imageUrl} /> : ''}

          <h3 className={styles.subtitle}>{t('bmpInfo.riskType')}</h3>
          <p>{bmpInfo?.risks.join(', ')}</p>
          <h3 className={styles.subtitle}>{t('bmpInfo.pollutantType')}</h3>
          <p>{bmpInfo?.pollutants.join(', ')}</p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(BmpInfoContainer);
