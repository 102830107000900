import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export enum MarkerType {
  GENERAL,
  SUBSTANCE,
}

interface Props {
  color: string;
  markerType: MarkerType;
  active?: boolean;
  disabled?: boolean;
  onClick?: Function;
}

const Marker = styled.button<any>`
  position: absolute;
  width: 34px;
  height: 34px;
  top: -80%;
  left: -80%;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid white;
  background-clip: content-box;
  background-color: ${props => props.color};
  border-color: ${props => rgba(props.color, 1)};
  padding: 0;
  box-sizing: content-box;
  cursor: pointer;

  ${(props: Props) =>
    props.markerType === MarkerType.GENERAL &&
    css`
      border-color: ${(props: Props) => rgba(props.color, 0)};
    `};

  ${(props: Props) =>
    props.markerType === MarkerType.SUBSTANCE &&
    css`
      width: 25px;
      height: 25px;
      border-color: ${(props: Props) => rgba(props.color, 0.5)};
    `};

  ${props =>
    props.active &&
    css`
      border-color: white;
      box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.75);
    `};

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      outline: none;
    `};
`;
Marker.defaultProps = {
  active: false,
  disabled: false,
};

export default Marker;
