import Axios from 'axios';
import * as axiosUtils from '../utils/axios.utils';

export async function getParcel(
  catchmentId: number,
  parcelCode: number
): Promise<any> {
  try {
    const headers = axiosUtils.commonHeaders();
    const response = await Axios.get(
      `/api/catchments/${catchmentId}/parcels/${parcelCode}`,
      {
        headers,
      }
    );
    return response?.data ? response.data : null;
  } catch (error) {
    throw error;
  }
}

export async function getBmp(bmpId: number): Promise<any> {
  try {
    const headers = axiosUtils.commonHeaders();
    const response = await Axios.get(`/api/bestmanagementpractices/${bmpId}`, {
      headers,
    });
    return response?.data ? response.data : null;
  } catch (error) {
    throw error;
  }
}
