import constants from './constants';

import { Period, SourceType, PredefinedPeriod } from '../../types';
import * as dateUtils from './../../date-utils';

export interface State {
  data: {
    catchmentId: number;
    source: SourceType;
    dates: Date[];
  }[];
  activePeriod: Period;
}

const initialPeriod = dateUtils.defineDateRange(PredefinedPeriod['1_year_ago']);

export const initialState: State = {
  data: [],
  activePeriod: {
    from: initialPeriod[0].getTime(),
    to: initialPeriod[1].getTime(),
  },
};

interface Action {
  type: string;
  payload: any;
}

const period = (state = initialState, action: Action): State => {
  switch (action.type) {
    case constants.LOAD_CATCHMENT_ACTIVE_DATES_FULFILLED:
      const { payload } = action;
      const dataToKeep = state.data.filter(dataItem => {
        return (
          dataItem.catchmentId !== payload.catchmentId &&
          dataItem.source !== payload.source
        );
      });
      return {
        ...state,
        data: [
          ...dataToKeep,
          {
            catchmentId: payload.catchment.id,
            source: payload.source.key,
            dates: payload.dates,
          },
        ],
      };

    case constants.SET_ACTIVE_PERIOD:
      return {
        ...state,
        activePeriod: {
          from: setHoursMinutesSeconds(action.payload.from, 0, 0, 0).getTime(),
          to: setHoursMinutesSeconds(action.payload.to, 23, 59, 59).getTime(),
        },
      };

    default:
      return state;
  }
};

function setHoursMinutesSeconds(
  date: Date,
  hours: number,
  minutes: number,
  seconds: number
): Date {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours,
    minutes,
    seconds
  );
}

export default period;
