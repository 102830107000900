import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { divIcon, MarkerCluster, DivIcon } from 'leaflet';
import { Marker as LeafletMarker } from 'react-leaflet';
import color from 'color';
import ReactDOMServer from 'react-dom/server';
import {
  loadGeneralStatuses,
  // setActiveWQL,
} from '../../redux/water-quality-locations/actions';
import {
  getActiveCatchment,
  getWQLGeneralStatuses,
  getActiveSource,
  getActivePeriod,
} from '../../selectors';
import {
  RootState,
  Period,
  Catchment,
  WQLGeneralStatus,
  Source,
  GeneralStatus,
} from '../../types';
import { didScopeChange } from '../../utils';
import { withRouter, RouteComponentProps } from 'react-router';
import Marker, { MarkerType } from '../../components/marker';
import Icon from '../../components-marvin/icon';

interface OwnProps {
  activeCatchment: Catchment | undefined;
  activeSource: Source | undefined;
  activePeriod: Period;
  // TODO: clean up activeWQL type
  locations: WQLGeneralStatus[];
}

type Props = OwnProps & RouteComponentProps<{ locationId: any }> & DispatchProp;

class WQLOnMap extends React.Component<Props, {}> {
  displayName = 'LeafletChild';

  componentDidMount() {
    this.loadLocations();
  }

  componentDidUpdate(prevProps: Props) {
    const scopeChanged = didScopeChange(prevProps, this.props);

    if (scopeChanged) {
      this.loadLocations();
    }
  }

  loadLocations() {
    this.props.dispatch(loadGeneralStatuses());
  }

  handleMarkerClick(wql: WQLGeneralStatus) {
    this.props.history.push(`/water-quality-locations/${wql.id}`);
  }

  render() {
    // const activeLocation = 1;
    return (
      <React.Fragment>
        <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
          {this.props.locations.map(location => {
            const color = getMarkerColor(location);
            const position = [
              location.location_4326.coordinates[1],
              location.location_4326.coordinates[0],
            ];

            return (
              <LeafletMarker
                key={[location.id, location.generalStatus].join('-')}
                position={{ lat: position[0], lng: position[1] }}
                onClick={this.handleMarkerClick.bind(this, location)}
                attribution={JSON.stringify(location)}
                icon={divIcon({
                  html: ReactDOMServer.renderToString(
                    <div>
                      <Marker
                        active={
                          parseInt(this.props.match.params.locationId) ===
                          location.id
                        }
                        color={color}
                        markerType={MarkerType.GENERAL}
                        disabled={
                          location.generalStatus !==
                          GeneralStatus.status_exceedance
                        }
                        onClick={this.handleMarkerClick.bind(this, location)}
                      >
                        {this.renderMarkerIcon(location)}
                      </Marker>
                    </div>
                  ),
                })}
              />
            );
          })}
        </MarkerClusterGroup>
      </React.Fragment>
    );
  }

  renderMarkerIcon(location: WQLGeneralStatus) {
    let iconName = null;
    if (location.generalStatus === GeneralStatus.status_ok) {
      iconName = 'check';
    }
    if (location.generalStatus === GeneralStatus.status_exceedance) {
      iconName = 'exclamation-triangle';
    }
    if (location.generalStatus === GeneralStatus.no_data) {
      iconName = 'times-circle';
    }
    if (iconName) return <Icon name={iconName} fill="white" size={18} />;
  }
}

// TODO: map marker color to legend
function getMarkerColor(substance: WQLGeneralStatus): string {
  switch (substance.generalStatus) {
    case GeneralStatus.no_data:
      return '#D4D4D4';
    case GeneralStatus.status_ok:
      return '#67AC44';
    case GeneralStatus.status_exceedance:
      return '#C30000';
    default:
      return '#000';
  }
}

function getMarkerScore(substance: WQLGeneralStatus): number {
  switch (substance.generalStatus) {
    case GeneralStatus.no_data:
      return 0;
    case GeneralStatus.status_ok:
      return 1;
    case GeneralStatus.status_exceedance:
      return 2;
    default:
      return -1;
  }
}

function createClusterCustomIcon(cluster: MarkerCluster): DivIcon {
  const childMarkers = cluster.getAllChildMarkers();
  const locations: WQLGeneralStatus[] = childMarkers.map(
    marker =>
      marker.options.attribution && JSON.parse(marker.options.attribution)
  );
  const maxLocation: WQLGeneralStatus = locations.reduce((prev, current) => {
    if (getMarkerScore(current) > getMarkerScore(prev)) {
      return current;
    }
    return prev;
  });

  const backgroundcolor: string = getMarkerColor(maxLocation);

  const css = `
      position: absolute;
      width: 34px;
      height: 34px;
      text-align: center;
      line-height: 34px;
      border-radius: 100%;
      background: ${backgroundcolor};
      display: flex;
      color: ${color(backgroundcolor).isDark() ? 'white' : 'black'};
      font-weight: bold;
      font-size: 1.1rem;
      justify-content: center;
      align-items: center;
      background-clip: content-box;
      padding: 0;
      box-sizing: content-box;
      cursor: pointer;`;

  return divIcon({
    html: `<span style="${css}">${cluster.getChildCount()}</span>`,
    className: 'marker-cluster-custom',
  });
}

const mapStateToProps = (state: RootState) => {
  return {
    activeCatchment: getActiveCatchment(state),
    activeSource: getActiveSource(state),
    activePeriod: getActivePeriod(state),
    locations: getWQLGeneralStatuses(state),
  };
};

export default withRouter(connect(mapStateToProps)(WQLOnMap));
