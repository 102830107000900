import { subDays, subMonths, subYears } from 'date-fns/esm';
import { PredefinedPeriod } from './types';

export function defineDateRange(period: PredefinedPeriod): [Date, Date] {
  if (period === PredefinedPeriod['30_days_ago']) {
    return [subDays(new Date(), 30), new Date()];
  }
  if (period === PredefinedPeriod['6_months_ago']) {
    return [subMonths(new Date(), 6), new Date()];
  }
  if (period === PredefinedPeriod['1_year_ago']) {
    return [subYears(new Date(), 1), new Date()];
  }

  console.warn(`Period "${period}"not know, returning today`);
  return [new Date(), new Date()];
}
