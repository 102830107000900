import { i18n } from './i18n';

export class PayloadTooLargeError extends Error {
  constructor(size: string) {
    super(i18n.t('errors.payloadTooLargeError', { size }));
    this.name = 'payloadTooLarge';
  }
}

export const ServerError = () =>
  new Error('Something went wrong, please contact a system administrator.');
