import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore: TODO: add custom module definition
import Flex, { FlexItem } from 'styled-flex-component';

import { Substance } from '../../types';
import { Heading } from '../../components/design-system';
import Button from '../../components-marvin/button';
import styles from './styles.module.scss';
import { find } from 'lodash-es';

interface Props {
  // TODO: type this better
  parameters: any[];
  // substances: Substance[];
  onSubmit: (substance: Substance) => void;
}

export function ParameterPane(props: Props) {
  const { parameters } = props;

  const { t } = useTranslation();

  const [selectedSubstance, setSelectedSubstance] = useState<Substance | null>(
    null
  );

  /**
   * Memo
   */
  const substances = useMemo(() => {
    if (parameters.length > 0) return parameters[0].substances;
    else return [];
  }, [parameters]);

  /**
   * Effects
   */
  useEffect(
    function autoSelectSubstance() {
      if (substances) {
        setSelectedSubstance(substances[0]);
      }
    },
    [substances]
  );

  /**
   * Functions
   */
  function handleChange(e: React.FormEvent<HTMLSelectElement>) {
    const substance = find(substances, {
      id: parseInt(e.currentTarget.value),
    });
    setSelectedSubstance(substance || null);
  }

  function handleSubmit() {
    if (!selectedSubstance) return;
    props.onSubmit(selectedSubstance);
  }

  // render the component
  return render();

  function render() {
    if (parameters.length === 0) return renderNoData();
    else return renderForm();
  }

  function renderNoData() {
    return (
      <Flex full column justifyBetween>
        <FlexItem>
          <p>{t('substanceAnalysis.noDataAvaillable')}</p>
        </FlexItem>
      </Flex>
    );
  }
  function renderForm() {
    return (
      <Flex full column justifyBetween>
        <FlexItem>
          <Heading.h2 className={styles.h2}>
            {t('substanceAnalysis.titleParameters')}
          </Heading.h2>
          <select
            value={selectedSubstance?.id || undefined}
            onChange={handleChange}
          >
            {parameters[0].substances.map((substance: Substance) => {
              return (
                <option key={substance.id} value={substance.id}>
                  {substance.name}
                </option>
              );
            })}
          </select>
        </FlexItem>

        <FlexItem alignEnd>
          <Flex justifyEnd>
            <Button onClick={handleSubmit}>
              {t('substanceAnalysis.actionApply')}
            </Button>
          </Flex>
        </FlexItem>
      </Flex>
    );
  }
}
