import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import AppContainer from './containers/app.container/app.container';

import 'normalize.css';
import 'leaflet/dist/leaflet.css';
import './styles/app.scss';

const MOUNT_NODE = document.getElementById('app');

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Route path="/" component={AppContainer} />
    </Router>
  </Provider>,
  MOUNT_NODE
);
