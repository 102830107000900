import * as React from 'react';
import RadioToggle from './radio-toggle';
import { noop } from 'lodash-es';

interface Props {
  isActive: boolean;
  title: string;
  description?: string;
  previewUrl: string;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const BackgroundMapListItem = (props: Props) => {
  return (
    <div className="background-map-list-item" onClick={props.onClick}>
      <div className="bg-preview">
        <img src={props.previewUrl} alt="background-preview" />
      </div>
      <div className="bg-information">
        <div className="bg-title">{props.title}</div>
      </div>
      <RadioToggle isActive={props.isActive} className="bg-toggle" />
    </div>
  );
};

BackgroundMapListItem.defaultProps = {
  onClick: noop,
};

export default BackgroundMapListItem;
