import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

interface Props {
  isActive: boolean;
  className?: string;
}

const RadioToggle = (props: Props) => {
  const cx = classnames('radio-toggle', props.className, {
    'is-active': props.isActive,
  });

  return <div className={cx} />;
};

RadioToggle.propTypes = {
  isActive: PropTypes.bool,
};

RadioToggle.defaultProps = {
  isActive: false,
};

export default RadioToggle;
