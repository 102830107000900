import * as React from 'react';
import { LocationMeasurementsYearly, ShapeAndAnnotation } from '../../types';
import { useTranslation } from 'react-i18next';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import { PlotData } from 'plotly.js';
const Plot = createPlotlyComponent(Plotly);

interface Props {
  data: LocationMeasurementsYearly;
}

const SubstanceGraphYearly = ({ data }: Props) => {
  const { t } = useTranslation();

  const traceAnnualAverage: Partial<PlotData>[] = React.useMemo(() => {
    const colors = ['#730000', '#a63b06', '#d66f03', '#ffa600'];

    return data.substanceWaterQualityList.map((waterQualityItem, i) => {
      return {
        x: waterQualityItem.annualAverageWaterQualityList.map(
          item => new Date(item.time)
        ),
        y: waterQualityItem.annualAverageWaterQualityList.map(
          item => item.value
        ),
        type: 'scatter',
        mode: 'markers',
        marker: { color: colors[i] },
        name: `${t('substanceStatusYearly.yearlyAverage')} ${
          waterQualityItem.substanceName
        }`,
      };
    });
  }, [data.substanceWaterQualityList, t]);

  const traces = React.useMemo(() => {
    return [...traceAnnualAverage];
  }, [traceAnnualAverage]);

  const upperLimits: ShapeAndAnnotation[] = React.useMemo(() => {
    const result = data.substanceWaterQualityList.reduce(
      (
        shapeAndAnnotations: ShapeAndAnnotation[],
        waterQualityItem
      ): ShapeAndAnnotation[] => {
        if (!waterQualityItem.standard) return shapeAndAnnotations;
        if (!waterQualityItem.standard.upperLimit) return shapeAndAnnotations;

        // else
        const { upperLimit } = waterQualityItem.standard;

        const newShapeAndAnnotation: ShapeAndAnnotation = {
          shape: {
            name: 'Upper limit',
            type: 'line',
            xref: 'paper',
            x0: 0,
            x1: 1,
            y0: upperLimit,
            y1: upperLimit,
            line: {
              color: 'orange',
              width: 1,
            },
          },
          annotation: {
            showarrow: false,
            text: `${t('substanceStatusYearly.upperLimit')} ${upperLimit}`,
            x: 0.5,
            xref: 'paper',
            xanchor: 'center',
            y: upperLimit,
          },
        };

        return [...shapeAndAnnotations, newShapeAndAnnotation];
      },
      []
    );

    return result;
  }, [data.substanceWaterQualityList, t]);

  const lowerLimits: ShapeAndAnnotation[] = React.useMemo(() => {
    const result = data.substanceWaterQualityList.reduce(
      (
        shapeAndAnnotations: ShapeAndAnnotation[],
        waterQualityItem
      ): ShapeAndAnnotation[] => {
        if (!waterQualityItem.standard) return shapeAndAnnotations;
        if (!waterQualityItem.standard.lowerLimit) return shapeAndAnnotations;

        // else
        const { lowerLimit } = waterQualityItem.standard;

        const newShapeAndAnnotation: ShapeAndAnnotation = {
          shape: {
            name: 'Upper limit',
            type: 'line',
            xref: 'paper',
            x0: 0,
            y0: lowerLimit,
            x1: 1,
            y1: lowerLimit,
            line: {
              color: 'orange',
              width: 1,
            },
          },
          annotation: {
            showarrow: false,
            text: `${t('substanceStatusYearly.lowerLimit')} ${lowerLimit}`,
            x: 0.5,
            xref: 'paper',
            xanchor: 'center',
            y: lowerLimit,
          },
        };

        return [...shapeAndAnnotations, newShapeAndAnnotation];
      },
      []
    );

    return result;
  }, [data.substanceWaterQualityList, t]);

  return render();

  function render() {
    return (
      <div>
        <Plot
          style={{ width: '100%', height: '100%' }}
          data={traces}
          layout={{
            showlegend: true,
            autosize: true,
            margin: { t: 40, b: 20, l: 60, r: 60 },
            yaxis: {
              rangemode: 'nonnegative',
              gridwidth: 2,
              ticksuffix: data.substanceUnit,
            },
            xaxis: {
              gridwidth: 2,
              dtick: 'M12',
              tickformat: '%Y',
            },
            shapes: [
              ...upperLimits.map(item => item.shape),
              ...lowerLimits.map(item => item.shape),
            ],
            annotations: [
              ...upperLimits.map(item => item.annotation),
              ...lowerLimits.map(item => item.annotation),
            ],
            legend: {
              orientation: 'h',
            },
          }}
          config={{
            modeBarButtonsToRemove: [
              'sendDataToCloud',
              'autoScale2d',
              'hoverClosestCartesian',
              'hoverCompareCartesian',
              'lasso2d',
              'select2d',
              'toggleSpikelines',
            ],
            displaylogo: false,
          }}
        />
      </div>
    );
  }
};

export default SubstanceGraphYearly;
