import React, { useState } from 'react';
import AccordionSection from './accordion-section';
import styles from './accordion.module.scss';
import { xor } from 'lodash-es';

interface Props {
  defaultOpenSections?: number[];
  items: { label: string; content: React.ReactNode }[];
}

const Accordion = ({ defaultOpenSections = [], items }: Props) => {
  const [openSections, setOpenSections] = useState<number[]>(
    defaultOpenSections
  );

  const onClick = (index: number): void => {
    setOpenSections(xor(openSections, [index]));
  };

  return (
    <div className={styles.accordion}>
      {items.map(
        (item: { label: string; content: React.ReactNode }, index: number) => (
          <AccordionSection
            key={index}
            isOpen={openSections.includes(index)}
            label={item.label}
            onClick={() => {
              onClick(index);
            }}
          >
            {item.content}
          </AccordionSection>
        )
      )}
    </div>
  );
};

export default Accordion;
