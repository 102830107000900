import { Legend } from '../types';
import legends from './../constants/legends';
import { find } from 'lodash-es';
import { i18n } from '../i18n';

export function getLegend(key: string): Legend | null {
  const legend = find(legends, { key });

  if (legend) {
    return {
      ...legend,
      title: i18n.t(legend.title),
      legendItems: legend.legendItems.map(legendItem => {
        return { ...legendItem, label: i18n.t(legendItem.label) };
      }),
    };
  }

  return null;
}
