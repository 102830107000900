import { find } from 'lodash-es';
import { createSelector } from 'reselect';
import { baseLayers } from '../config';

import {
  RootState,
  Period,
  Catchment,
  Source,
  WQLGeneralStatus,
  LanguageItem,
  BaseLayer,
} from './../types';

export const getActiveLanguage = (state: RootState): LanguageItem => {
  return state.preferences.language;
};

export const getActiveBaseLayer = (state: RootState): BaseLayer => {
  const { baselayerKey } = state.map;
  const baseLayer = find(baseLayers, { key: baselayerKey });
  if (baseLayer) return baseLayer;
  return baseLayers[0];
};

export const getCatchments = (state: RootState): Catchment[] => {
  return state.catchments.data;
};

export function getActiveCatchment(state: RootState): Catchment | undefined {
  const catchments: Catchment[] = state.catchments.data;
  const catchment = find(catchments, {
    id: state.catchments.activeCatchmentId,
  });
  return catchment;
}

export const getActiveSource = createSelector(
  getActiveCatchment,
  (state: RootState) => state.catchments.activeSource,
  (
    activeCatchment: Catchment | undefined,
    activeSource: Source | null
  ): Source | undefined => {
    if (!activeCatchment) return undefined;

    if (activeSource === null) {
      return activeCatchment.sources[0];
    }
    return activeSource;
  }
);

export const getActiveDates = createSelector(
  getActiveCatchment,
  getActiveSource,
  state => state,
  (
    catchment: Catchment | undefined,
    source: Source | undefined,
    state
  ): Date[] => {
    if (!catchment || !source) return [];

    const catchmentActiveDates = find(state.period.data, {
      catchmentId: catchment.id,
      source: source.key,
    });

    if (catchmentActiveDates)
      return catchmentActiveDates.dates.map(date => new Date(date));
    return [];
  }
);

export const getActivePeriod = (state: RootState): Period => {
  return state.period.activePeriod;
};

export const initialDataFetched = (state: RootState): boolean => {
  return !state.init.isLoading && !state.init.isError;
};

export const getWQLGeneralStatuses = (state: RootState): WQLGeneralStatus[] => {
  return state.waterQualityLocations.generalStatusData;
};
