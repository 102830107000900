import { Catchment, Source } from '../../types';
import {
  CatchmentActionTypes,
  SET_ACTIVE_CATCHMENT,
  LOAD_CATCHMENTS_PENDING,
  LOAD_CATCHMENTS_FULFILLED,
  TOGGLE_ACTIVE_LAYER,
  SET_ACTIVE_PARCEL_LAYER,
} from './types';

export interface State {
  data: Catchment[];
  isPending: boolean;
  isError: boolean;
  errorMessage: string;
  activeCatchmentId: number;
  activeSource: Source | null;
  activeParcelLayerId: number | null;
}

const initialState: State = {
  data: [],
  isPending: true,
  isError: false,
  errorMessage: '',
  activeCatchmentId: 0,
  activeSource: null,
  activeParcelLayerId: null,
};

const catchments = (
  state = initialState,
  action: CatchmentActionTypes
): State => {
  switch (action.type) {
    case LOAD_CATCHMENTS_PENDING:
      return {
        ...state,
        isPending: true,
        isError: false,
      };

    case LOAD_CATCHMENTS_FULFILLED:
      // transform catchments for application so catchments without `sources` are ignored
      const catchmentsWithSource = action.payload.filter(
        (catchment: Catchment) => catchment.sources.length > 0
      );

      const catchments = catchmentsWithSource.map((catchment: Catchment) => {
        const layers = catchment.layers.map(layer => {
          function defineIsActive() {
            if (layer.defaultLayer) return true;
            if (layer.id === state.activeParcelLayerId) return true;
            return false;
          }
          return {
            ...layer,
            // set initial isActive value
            isActive: defineIsActive(),
          };
        });
        return { ...catchment, layers };
      });

      // set initial activeCatchment
      const activeCatchmentId = state.activeCatchmentId
        ? state.activeCatchmentId
        : catchments[0].id;

      const activeSource = state.activeSource
        ? state.activeSource
        : catchments[0].sources[0];

      return {
        ...state,
        isPending: false,
        data: catchments,
        activeCatchmentId,
        activeSource,
      };

    case SET_ACTIVE_CATCHMENT:
      return {
        ...state,
        activeCatchmentId: action.payload.catchment.id,
        activeSource: action.payload.source,
      };

    case TOGGLE_ACTIVE_LAYER:
      return {
        ...state,
        data: state.data.map(catchment => {
          if (catchment.id === action.payload.catchment.id) {
            return {
              ...catchment,
              layers: catchment.layers.map(layer => {
                if (layer.id === action.payload.layerId) {
                  return { ...layer, isActive: !layer.isActive };
                }
                return layer;
              }),
            };
          }
          return catchment;
        }),
      };

    case SET_ACTIVE_PARCEL_LAYER:
      return {
        ...state,
        data: state.data.map(catchment => {
          if (catchment.id === action.payload.catchment.id) {
            const layers = [...catchment.layers];
            // first reset the active parcel layers
            layers.forEach(layer => {
              if (layer.parcelMap) {
                layer.isActive = false;
              }
            });
            return {
              ...catchment,
              layers: layers.map(layer => {
                if (layer.id === action.payload.layerId) {
                  return { ...layer, isActive: true };
                }
                return layer;
              }),
            };
          }
          return catchment;
        }),
        activeParcelLayerId: action.payload.layerId,
      };

    default:
      return state;
  }
};

export default catchments;
