import React, { useMemo } from 'react';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import { PlotData, Layout } from 'plotly.js';
import { ErosionData } from '../../types';
import { useTranslation } from 'react-i18next';
const Plot = createPlotlyComponent(Plotly);

interface Props {
  data: ErosionData[];
}

const Erosiongraph = ({ data }: Props) => {
  const { t } = useTranslation();
  const labels: string[] = useMemo(
    () =>
      data.map((erosionElement: ErosionData) => {
        return erosionElement.erosionClass.translation;
      }),
    [data]
  );

  const colors: string[] = useMemo(
    () =>
      data.map((erosionElement: ErosionData) => {
        return erosionElement.color;
      }),
    [data]
  );

  const values: number[] = useMemo(
    () =>
      data.map((erosionElement: ErosionData) => {
        return erosionElement.area_m2;
      }),
    [data]
  );

  const plotData: Partial<PlotData>[] = [
    {
      values,
      labels,
      type: 'pie',
      marker: {
        colors,
      },
    },
  ];

  const layout: Partial<Layout> = {
    width: 500,
  };

  const config: any = {
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'autoScale2d',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'lasso2d',
      'select2d',
      'toggleSpikelines',
    ],
    displaylogo: false,
  };

  return (
    <React.Fragment>
      {data.length ? (
        <React.Fragment>
          <p>{t('erosion.description')}</p>
          <Plot data={plotData} layout={layout} config={config} />
        </React.Fragment>
      ) : (
        <p>{t('erosion.noData')}</p>
      )}
    </React.Fragment>
  );
};

export default Erosiongraph;
