import * as React from 'react';
// @ts-ignore: TODO: insufficient type definition
import Flex from 'styled-flex-component';
import { LocationMeasurementsDaily, SourceType } from '../../types';
import { useTranslation } from 'react-i18next';
import { getActiveCatchment } from '../../selectors';
import { useSelector } from 'react-redux';
import { catchmentConfig } from '../../config/catchment-config';
import { SubstanceGraphDaily } from '../graphs/substance-graph-daily';

import styles from './styles.module.scss';

export interface Props {
  data: LocationMeasurementsDaily;
  source: SourceType;
}

function SubstanceStatusDaily(props: Props) {
  const { data, source } = props;

  const { t } = useTranslation();
  const activeCatchment = useSelector(getActiveCatchment);

  const { groundWaterLevelUnit, surfaceWaterLevelUnit } = data;

  const selectSurfaceWaterTranslation = () => {
    const activeConfig = catchmentConfig.filter(catchmentConfig => {
      return (
        catchmentConfig.countryKey === activeCatchment?.country.key &&
        catchmentConfig.name === activeCatchment?.name
      );
    });
    return (
      activeConfig && activeConfig[0] && activeConfig[0].surfaceWaterTranslation
    );
  };

  return (
    <Flex column>
      <SubstanceGraphDaily
        data={data}
        source={source}
        waterLevelLabel={
          source === SourceType.GROUND_WATER
            ? t('substanceStatusDaily.groundWaterLevelLabel')
            : t(
                `substanceStatusDaily.surfaceWater${selectSurfaceWaterTranslation()}Label`
              )
        }
        waterLevelUnit={
          source === SourceType.GROUND_WATER
            ? groundWaterLevelUnit
            : surfaceWaterLevelUnit
        }
      />

      <ul className={styles.graphDetails}>
        {data.substanceWaterQualityList.map(waterQualityItem => {
          if (waterQualityItem.substanceLabel.translation === '') return null;
          else
            return (
              <li key={waterQualityItem.substanceDescription.key}>
                <strong>{waterQualityItem.substanceLabel.translation}:</strong>
                {waterQualityItem.substanceDescription.translation}
              </li>
            );
        })}
        <li>
          <strong>{t('substanceStatusDaily.precipitationLocation')}:</strong>
          {data.precipitationLocation}
        </li>

        {source === SourceType.GROUND_WATER && (
          <li>
            <strong>
              {t('substanceStatusDaily.groundWaterLevelMeasureLocation')}:
            </strong>
            {data.groundWaterLevelLocation}
          </li>
        )}

        {source === SourceType.SURFACE_WATER && (
          <li>
            <strong>
              {t(
                `substanceStatusDaily.surfaceWater${selectSurfaceWaterTranslation()}MeasureLocation`
              )}
              :
            </strong>
            {data.surfaceWaterLevelLocation}
          </li>
        )}
      </ul>
    </Flex>
  );
}

export default SubstanceStatusDaily;
