import { toNumber } from 'lodash-es';
import Axios from 'axios';
import * as L from 'leaflet';

const baseUrl = ' https://nominatim.openstreetmap.org';

interface Location {
  id: string;
  lat: number;
  lng: number;
  formattedAddres: string;
  boundingbox: L.LatLngBoundsExpression;
}

interface GetLocationOptions {
  count: number;
}

export async function getLocation(
  query: string,
  customOptions: GetLocationOptions
): Promise<Location> {
  try {
    const url = `${baseUrl}/search`;
    const defaultOptions: GetLocationOptions = { count: 5 };
    const options = { ...defaultOptions, ...customOptions };

    const config = {
      params: {
        format: 'json',
        q: query,
        limit: options.count,
      },
    };
    const response = await Axios(url, config);

    const formattedResponse = response.data.map((item: any) => {
      return {
        id: item.osm_id,
        lat: toNumber(item.lat),
        lng: toNumber(item.lon),
        formattedAddress: item.display_name,
        boundingbox: [
          [item.boundingbox[0], item.boundingbox[2]],
          [item.boundingbox[1], item.boundingbox[3]],
        ],
      };
    });

    return formattedResponse;
  } catch (error) {
    throw error;
  }
}

interface ReverseLocation {
  displayName: string;
  country: string;
  countryCode: string;
}

interface ReverseLocationOptions {
  zoom: number;
}

export async function getReverseLocation(
  lat: number,
  lng: number,
  customOptions: ReverseLocationOptions
): Promise<ReverseLocation> {
  try {
    const url = `${baseUrl}/reverse`;
    const defaultOptions: ReverseLocationOptions = {
      zoom: 18, // zoom can be 0 (Country), or 18 (house/building), note that at the time of writing zoom level 0 does not fully covers Europe
    };
    const options = { ...defaultOptions, ...customOptions };

    const config = {
      params: {
        format: 'json',
        lat,
        lon: lng,
        zoom: options.zoom,
      },
    };
    const { data } = await Axios(url, config);

    const formattedResponse = {
      displayName: data.display_name,
      country: data.address.country,
      countryCode: data.address.country_code,
    };
    return formattedResponse;
  } catch (error) {
    throw error;
  }
}
