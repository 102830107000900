import * as React from 'react';
import classnames from 'classnames';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

interface Props {
  className?: string;
  value: number;
  total: number;
  valueFill?: string;
  bgFill?: string;
  valueEntity?: string;
}

class Pauge extends React.Component<Props, {}> {
  static defaultProps = {
    valueFill: '#0088FE',
    bgFill: '#e0dede',
  };

  render() {
    const data = [
      {
        value: (this.props.value / this.props.total) * 100,
        color: this.props.valueFill,
      },
      {
        value: 100 - (this.props.value / this.props.total) * 100,
        color: this.props.bgFill,
      },
    ];
    return (
      <div className={classnames('pauge', this.props.className)}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              startAngle={180}
              endAngle={0}
              innerRadius={70}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={0}
              isAnimationActive={false}
            >
              {data.map((entry, index) => (
                <Cell key={index} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

        <div className="value-hero">
          <div className="row">
            <div className="value">{this.props.value}</div>
          </div>
          <div className="row">
            <div className="start-value">{0}</div>
            <div className="entity">{this.props.valueEntity}</div>
            <div className="total-value">{this.props.total}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pauge;
