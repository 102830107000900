import constants from './constants';
import {
  getActiveCatchment,
  getActiveSource,
  getActivePeriod,
} from '../../selectors';
import * as waterLocationService from '../../services/water-location.service';
import { Dispatch } from 'redux';
import { RootState } from '../../types';

export function loadGeneralStatuses(): any {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const state = getState();
    const activeCatchment = getActiveCatchment(state);
    const activeSource = getActiveSource(state);
    const activePeriod = getActivePeriod(state);

    if (!activeCatchment || !activeSource) {
      console.warn(
        'can not load generalStatuses becasue no activeCatchment or activeSource'
      );
      return;
    }

    return dispatch({
      type: constants.LOAD_WQL_GENERAL_STATUS,
      payload: waterLocationService.getGeneralStatuses(
        activeCatchment.id,
        activeSource.key,
        activePeriod
      ),
    });
  };
}
