import * as React from 'react';
import { LocationMeasurementsMonthly, ShapeAndAnnotation } from '../../types';
import { useTranslation } from 'react-i18next';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import { PlotData } from 'plotly.js';
const Plot = createPlotlyComponent(Plotly);

interface Props {
  data: LocationMeasurementsMonthly;
}

export function SubstanceGraphMonthly(props: Props) {
  const { data } = props;
  const { t } = useTranslation();

  const [activeWaterqualityItem, setActiveWaterqualityItem] = React.useState(
    data.substanceWaterQualityList[0] //.substanceName
  );

  const tracesMonthlyAverage: Partial<PlotData> = React.useMemo(() => {
    return {
      x: activeWaterqualityItem.monthlyAverageWaterQualityList.map(
        item => new Date(item.time)
      ),
      y: activeWaterqualityItem.monthlyAverageWaterQualityList.map(
        item => item.value
      ),
      type: 'bar',
      marker: { color: 'red' },
      name: `${t('substanceStatusMonthly.monthlyAverage')} ${
        activeWaterqualityItem.substanceName
      }`,
    };
  }, [activeWaterqualityItem, t]);

  const tracesMonthlyMaximum: Partial<PlotData> = React.useMemo(() => {
    return {
      x: activeWaterqualityItem.monthlyMaximumWaterQualityList.map(
        item => new Date(item.time)
      ),
      y: activeWaterqualityItem.monthlyMaximumWaterQualityList.map(
        item => item.value
      ),
      type: 'scatter',
      mode: 'markers',
      marker: { color: 'blue' },
      name: `${t('substanceStatusMonthly.monthlyMaximum')} ${
        activeWaterqualityItem.substanceName
      }`,
    };
  }, [activeWaterqualityItem, t]);

  const traces = React.useMemo(() => {
    return [tracesMonthlyAverage, tracesMonthlyMaximum];
  }, [tracesMonthlyAverage, tracesMonthlyMaximum]);

  const upperLimits: ShapeAndAnnotation[] = React.useMemo(() => {
    const result = data.substanceWaterQualityList.reduce(
      (
        shapeAndAnnotations: ShapeAndAnnotation[],
        waterQualityItem
      ): ShapeAndAnnotation[] => {
        if (!waterQualityItem.standard) return shapeAndAnnotations;
        if (!waterQualityItem.standard.upperLimit) return shapeAndAnnotations;

        // else
        const { upperLimit } = waterQualityItem.standard;

        const newShapeAndAnnotation: ShapeAndAnnotation = {
          shape: {
            name: 'Upper limit',
            type: 'line',
            xref: 'paper',
            x0: 0,
            x1: 1,
            y0: upperLimit,
            y1: upperLimit,
            line: {
              color: 'orange',
              width: 1,
            },
          },
          annotation: {
            showarrow: false,
            text: `${t('substanceStatusMonthly.upperLimit')} ${upperLimit}`,
            x: 0.5,
            xref: 'paper',
            xanchor: 'center',
            y: upperLimit,
          },
        };

        return [...shapeAndAnnotations, newShapeAndAnnotation];
      },
      []
    );

    return result;
  }, [data.substanceWaterQualityList, t]);

  const lowerLimits: ShapeAndAnnotation[] = React.useMemo(() => {
    const result = data.substanceWaterQualityList.reduce(
      (
        shapeAndAnnotations: ShapeAndAnnotation[],
        waterQualityItem
      ): ShapeAndAnnotation[] => {
        if (!waterQualityItem.standard) return shapeAndAnnotations;
        if (!waterQualityItem.standard.lowerLimit) return shapeAndAnnotations;

        // else
        const { lowerLimit } = waterQualityItem.standard;

        const newShapeAndAnnotation: ShapeAndAnnotation = {
          shape: {
            name: 'Upper limit',
            type: 'line',
            xref: 'paper',
            // yref: 'y2',
            x0: 0,
            y0: lowerLimit,
            x1: 1,
            y1: lowerLimit,
            line: {
              color: 'orange',
              width: 1,
            },
          },
          annotation: {
            showarrow: false,
            text: `${t('substanceStatusMonthly.lowerLimit')} ${lowerLimit}`,
            x: 0.5,
            xref: 'paper',
            xanchor: 'center',
            y: lowerLimit,
            // yref: 'y2',
          },
        };

        return [...shapeAndAnnotations, newShapeAndAnnotation];
      },
      []
    );

    return result;
  }, [data.substanceWaterQualityList, t]);

  const config: any = {
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'autoScale2d',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'lasso2d',
      'select2d',
      'toggleSpikelines',
    ],
    displaylogo: false,
  };

  function handleWaterQualityItemChange(
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    const result = data.substanceWaterQualityList.find(
      item => item.substanceName === event.target.value
    );
    if (result) setActiveWaterqualityItem(result);
  }

  return render();

  function render() {
    return (
      <>
        {/* Water Quality Item select */}
        <select
          value={activeWaterqualityItem.substanceName}
          onChange={handleWaterQualityItemChange}
        >
          {data.substanceWaterQualityList.map((waterqualityItem, i) => (
            <option key={i} value={waterqualityItem.substanceName}>
              {waterqualityItem.substanceName}
            </option>
          ))}
        </select>

        {/* Water Quality Item monthly plot */}
        <Plot
          style={{ width: '100%', height: '100%' }}
          data={traces}
          layout={{
            autosize: true,
            margin: { t: 40, b: 20, l: 60, r: 60 },
            showlegend: true,
            legend: {
              x: 0,
              y: -0.3,
              orientation: 'h',
            },
            barmode: 'group',
            yaxis: {
              rangemode: 'nonnegative',
              gridwidth: 2,
              ticksuffix: data.substanceUnit,
            },
            xaxis: {
              type: 'date',
              gridwidth: 2,
              dtick: 'M1',
              tickformat: '%d/%m/%y',
            },
            shapes: [
              ...upperLimits.map(item => item.shape),
              ...lowerLimits.map(item => item.shape),
            ],
            annotations: [
              ...upperLimits.map(item => item.annotation),
              ...lowerLimits.map(item => item.annotation),
            ],
          }}
          config={config}
        />
      </>
    );
  }
}
