import { LandUseData, ErosionData } from './../types';
import Axios from 'axios';
import {
  WQLGeneralStatus,
  SourceType,
  WQLNumberExceededSubstances,
  WQLSubstanceStatus,
  LocationMeasurementsDaily,
  LocationMeasurementsMonthly,
  LocationMeasurementsYearly,
  LocationMeasurementsSoilWaterStatus,
  WaterQualityLocation,
  Period,
} from '../types';
import * as axiosUtils from './../utils/axios.utils';

export async function getGeneralStatuses(
  catchmentId: number,
  source: SourceType,
  period: Period
): Promise<WQLGeneralStatus[]> {
  const headers = axiosUtils.commonHeaders();
  const response = await Axios.get(
    `/api/catchments/${catchmentId}/waterQualityLocations/generalStatus`,
    {
      headers,
      params: {
        source,
        startDateTime: period.from,
        endDateTime: period.to,
      },
    }
  );

  return response.data;
}

export async function getSubstanceStatuses(
  catchmentId: number,
  source: SourceType,
  period: Period,
  substanceId: number | string,
  standardType: string
): Promise<WQLSubstanceStatus[]> {
  const headers = axiosUtils.commonHeaders();
  const endpoint =
    standardType === 'parameter' ? 'Parameter' : 'PesticideNitrate';
  const body: any = {
    source,
    startDateTime: period.from,
    endDateTime: period.to,
    substance: substanceId,
  };
  if (standardType !== 'parameter') {
    body.standardType = standardType;
  }
  const response = await Axios.get(
    `/api/catchments/${catchmentId}/waterQualityLocations/substanceStatus${endpoint}`,
    {
      headers,
      params: body,
    }
  );

  return response.data;
}

export async function getWaterQualityLocation(
  wqlId: string
): Promise<WaterQualityLocation> {
  const headers = axiosUtils.commonHeaders();
  const response = await Axios.get(`/api/waterQualityLocations/${wqlId}`, {
    headers,
  });

  return response.data;
}

export async function getNumberExceededSubstances(
  wqlId: number,
  source: SourceType,
  period: Period
): Promise<WQLNumberExceededSubstances> {
  const headers = axiosUtils.commonHeaders();
  const response = await Axios.get(
    `/api/waterQualityLocations/${wqlId}/nbExceededSubstances`,
    {
      headers,
      params: {
        source,
        startDateTime: period.from,
        endDateTime: period.to,
      },
    }
  );

  return response.data;
}

export async function getMeasurementsDaily(
  locationId: number,
  source: SourceType,
  period: Period,
  substanceId: number,
  standardType: string
): Promise<LocationMeasurementsDaily> {
  const headers = axiosUtils.commonHeaders();
  const body: any = {
    source,
    startDateTime: period.from,
    endDateTime: period.to,
    substance: substanceId,
  };

  if (standardType !== 'parameter') {
    body.standardType = standardType;
  }
  const response = await Axios.get(
    `/api/waterQualityLocations/${locationId}/measurements/daily`,
    {
      headers,
      params: body,
    }
  );

  return response.data;
}

export async function getMeasurementsMonthly(
  locationId: number,
  source: SourceType,
  period: Period,
  substanceId: number,
  standardType: string
): Promise<LocationMeasurementsMonthly> {
  const headers = axiosUtils.commonHeaders();
  const body: any = {
    source,
    startDateTime: period.from,
    endDateTime: period.to,
    substance: substanceId,
  };

  if (standardType !== 'parameter') {
    body.standardType = standardType;
  }
  const response = await Axios.get(
    `/api/waterQualityLocations/${locationId}/measurements/monthly`,
    {
      headers,
      params: body,
    }
  );

  return response.data;
}

export async function getMeasurementsYearly(
  locationId: number,
  source: SourceType,
  period: Period,
  substanceId: number
): Promise<LocationMeasurementsYearly> {
  const headers = axiosUtils.commonHeaders();
  const response = await Axios.get(
    `/api/waterQualityLocations/${locationId}/measurements/annual`,
    {
      headers,
      params: {
        source,
        startDateTime: period.from,
        endDateTime: period.to,
        substance: substanceId,
      },
    }
  );

  return response.data;
}

export async function getMeasurementsSoilWaterStatus(
  locationId: number,
  period: Period
): Promise<LocationMeasurementsSoilWaterStatus> {
  const headers = axiosUtils.commonHeaders();
  const response = await Axios.get(
    `/api/waterQualityLocations/${locationId}/measurements/soilWaterStatus`,
    {
      headers,
      params: {
        startDateTime: period.from,
        endDateTime: period.to,
      },
    }
  );

  return response.data;
}

export async function getLandUseData(
  locationId: number
): Promise<LandUseData[]> {
  const headers = axiosUtils.commonHeaders();
  const response = await Axios.get(
    `/api/waterQualityLocations/${locationId}/landuse`,
    {
      headers,
    }
  );

  return response.data;
}

export async function getErosionData(
  locationId: number,
  period: Period
): Promise<ErosionData[]> {
  const headers = axiosUtils.commonHeaders();
  const response = await Axios.get(
    `/api/waterQualityLocations/${locationId}/erosion`,
    {
      headers,
      params: {
        startDateTime: period.from,
        endDateTime: period.to,
      },
    }
  );

  return response.data;
}
