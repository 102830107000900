export const constants = {
  INIT: 'INIT',
  INIT_REJECTED: 'INIT_REJECTED',
  INIT_FULFILLED: 'INIT_FULFILLED',
};

export interface InitState {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
}

// export type InitActionTypes = ;
