import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { css } from '@emotion/core';

const getCss = (textAlign: string) => {
  return css`
    text-align: right;
    width: 100%;
  `;
};

interface Props {
  textAlign?: string;
}

export const LoadingSpinner = (props: Props) => {
  return (
    <PulseLoader
      size={5}
      css={getCss(props.textAlign || 'center')}
      color={'lightgrey'}
      loading={true}
    />
  );
};
