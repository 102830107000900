import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

interface TitleProps {
  underlined?: boolean;
  as?: 'h1' | 'h2' | 'h3';
  children: any;
}
const h1 = (props: { className?: string; children: any }) => {
  return <h1 className={cx(styles.h1, props.className)}>{props.children}</h1>;
};
const h2 = (props: { className?: string; children: any }) => {
  return <h2 className={cx(styles.h2, props.className)}>{props.children}</h2>;
};

const h3 = (props: { className?: string; children: any }) => {
  return <h3 className={cx(styles.h3, props.className)}>{props.children}</h3>;
};

export const Heading = {
  h1,
  h2,
  h3,
};

export const Paragraph = (props: { children: any }) => {
  return <p className={styles.p}>{props.children}</p>;
};
