import React from 'react';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SubstanceAnalysisContainer from '../../containers/substance-analysis.container';
import ConfigurationContainer from '../../containers/configuration.container';
import InformationContainer from '../../containers/information.container';
import Tooltip from './../../components-marvin/tooltip';
import Icon from '../../components-marvin/icon';
import Sidebar from '../../components/sidebar';
import MeasuresContainer from '../../containers/measures.container/measures.container';
import { useSelector } from 'react-redux';
import { getActiveCatchment } from '../../selectors';
import { CatchmentLayer } from '../../types';

interface Props {}

const Navigation = (props: Props) => {
  const { t } = useTranslation();
  const activeCatchment = useSelector(getActiveCatchment);

  const catchmentContainsBmp = (): boolean => {
    if (!activeCatchment) {
      return false;
    }
    const layersWithBmp = activeCatchment.layers.filter(
      (layer: CatchmentLayer) => {
        return layer.bmpMap === true;
      }
    );
    return layersWithBmp.length > 0;
  };

  return (
    <React.Fragment>
      <Sidebar>
        <div className="top-navigation">
          <Tooltip text={t('navigation.waterQualityLocation')} position="right">
            <NavLink
              to="/water-quality-locations"
              className="navigation-item"
              activeClassName="active"
            >
              <Icon name="map-marked-alt" className="icon" />
            </NavLink>
          </Tooltip>
          <Tooltip text={t('navigation.substanceAnalysis')} position="right">
            <NavLink
              to="/substance-analysis"
              className="navigation-item"
              activeClassName="active"
            >
              <Icon name="vial" />
            </NavLink>
          </Tooltip>
          <Tooltip text={t('navigation.configuration')} position="right">
            <NavLink
              to="/configuratie"
              className="navigation-item"
              activeClassName="active"
            >
              <Icon name="sliders-h" />
            </NavLink>
          </Tooltip>
          {catchmentContainsBmp() ? (
            <Tooltip text={t('navigation.measures')} position="right">
              <NavLink
                to="/measures"
                className="navigation-item"
                activeClassName="active"
              >
                <Icon name="map-marked-alt" />
              </NavLink>
            </Tooltip>
          ) : (
            ''
          )}
          <Tooltip text={t('navigation.adminScreen')} position="right">
            <NavLink
              to="/admin"
              className="navigation-item"
              activeClassName="active"
            >
              <Icon name="cog" />
            </NavLink>
          </Tooltip>
          <div className="spacer" />
          <Tooltip text={t('navigation.moreInfo')} position="right">
            <NavLink
              to="/info"
              className="navigation-item"
              activeClassName="active"
            >
              <Icon name="info-circle" />
            </NavLink>
          </Tooltip>
        </div>

        <Switch>
          <Route
            path="/substance-analysis/limit/:limit/substance/:substanceId"
            exact={false}
            component={SubstanceAnalysisContainer}
          />
          <Route
            path="/substance-analysis"
            exact={false}
            component={SubstanceAnalysisContainer}
          />
          <Route path="/configuratie" component={ConfigurationContainer} />
          {catchmentContainsBmp() ? (
            <Route
              path="/measures"
              exact={false}
              component={MeasuresContainer}
            />
          ) : (
            ''
          )}
          <Route path="/info" component={InformationContainer} />
          <Redirect from="/" to="/water-quality-locations" exact />
        </Switch>
      </Sidebar>
    </React.Fragment>
  );
};

export default Navigation;
