import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Plot from 'react-plotly.js';
import { PlotData } from 'plotly.js';

import {
  LocationMeasurementsDaily,
  SourceType,
  ShapeAndAnnotation,
} from '../../types';

interface Props {
  data: LocationMeasurementsDaily;
  source: SourceType;
  waterLevelLabel: string;
  waterLevelUnit: string;
}
export const SubstanceGraphDaily = (props: Props) => {
  const { data } = props;

  const { t } = useTranslation();

  const traceSourceType = React.useMemo(
    () => traceForSourceType(props.source, data, props.waterLevelLabel),
    [data, props.source, props.waterLevelLabel]
  );

  const tracesWaterQualityBelowReportingLimit: Partial<
    PlotData
  >[] = React.useMemo(() => {
    const colors = ['#6ee387', '#d2c219', '#ffa600]'];

    return data.substanceWaterQualityList.map((waterQualityItem, i) => {
      const belowReportingLimit = waterQualityItem.waterQualityList.filter(
        item => item.belowReportingLimit
      );
      return {
        x: belowReportingLimit.map(item => new Date(item.time)),
        y: belowReportingLimit.map(item => item.value),
        type: 'scatter',
        // legendgroup: 'group2',
        mode: 'markers',
        marker: {
          color: colors[i],
          size: 8,
        },
        yaxis: 'y2',
        name: `${waterQualityItem.substanceLabel.translation} ${
          waterQualityItem.substanceName
        } ${t('substanceStatusDaily.belowDetectionLimit')}`,
      };
    });
  }, [data.substanceWaterQualityList, t]);

  const tracesWaterQualityAboveReportingLimit: Partial<
    PlotData
  >[] = React.useMemo(() => {
    const colors = ['#730000', '#d66f03', '#ffa600'];
    return data.substanceWaterQualityList.map((waterQualityItem, i) => {
      const aboveReportingLimit = waterQualityItem.waterQualityList.filter(
        item => !item.belowReportingLimit
      );
      return {
        x: aboveReportingLimit.map(item => new Date(item.time)),
        y: aboveReportingLimit.map(item => item.value),
        type: 'scatter',
        // legendgroup: 'group2',
        mode: 'markers',
        marker: {
          color: colors[i],
          size: 8,
        },
        yaxis: 'y2',
        name: `${waterQualityItem.substanceLabel.translation} ${waterQualityItem.substanceName}`,
      };
    });
  }, [data.substanceWaterQualityList]);

  const tracePrecipitation: Partial<PlotData> = React.useMemo(
    () => ({
      type: 'bar',
      legendgroup: 'group1',
      marker: { color: '#8884d8' },
      name: t('substanceStatusDaily.precipitationLabel'),
      visible: 'legendonly',
      x: data.precipitationList.map(item => new Date(item.time)),
      y: data.precipitationList.map(item => item.value),
    }),
    [data.precipitationList, t]
  );

  const traces = React.useMemo(() => {
    return [
      tracePrecipitation,
      ...(traceSourceType ? [traceSourceType] : []),
      ...tracesWaterQualityBelowReportingLimit,
      ...tracesWaterQualityAboveReportingLimit,
    ];
  }, [
    tracePrecipitation,
    traceSourceType,
    tracesWaterQualityAboveReportingLimit,
    tracesWaterQualityBelowReportingLimit,
  ]);

  const upperLimits: ShapeAndAnnotation[] = React.useMemo(() => {
    const result = data.substanceWaterQualityList.reduce(
      (
        shapeAndAnnotations: ShapeAndAnnotation[],
        waterQualityItem
      ): ShapeAndAnnotation[] => {
        if (!waterQualityItem.standard) return shapeAndAnnotations;
        if (!waterQualityItem.standard.upperLimit) return shapeAndAnnotations;

        // else
        const { upperLimit } = waterQualityItem.standard;

        const newShapeAndAnnotation: ShapeAndAnnotation = {
          shape: {
            name: 'Upper limit',
            type: 'line',
            xref: 'paper',
            yref: 'y2',
            x0: 0,
            y0: upperLimit,
            x1: 1,
            y1: upperLimit,
            line: {
              color: 'orange',
              width: 1,
            },
          },
          annotation: {
            showarrow: false,
            text: `${t('substanceStatusDaily.upperLimit')} ${upperLimit}`,
            x: 0.5,
            xref: 'paper',
            xanchor: 'center',
            y: upperLimit,
            yref: 'y2',
          },
        };

        return [...shapeAndAnnotations, newShapeAndAnnotation];
      },
      []
    );

    return result;
  }, [data.substanceWaterQualityList, t]);

  const lowerLimits: ShapeAndAnnotation[] = React.useMemo(() => {
    const result = data.substanceWaterQualityList.reduce(
      (
        shapeAndAnnotations: ShapeAndAnnotation[],
        waterQualityItem
      ): ShapeAndAnnotation[] => {
        if (!waterQualityItem.standard) return shapeAndAnnotations;
        if (!waterQualityItem.standard.lowerLimit) return shapeAndAnnotations;

        // else
        const { lowerLimit } = waterQualityItem.standard;

        const newShapeAndAnnotation: ShapeAndAnnotation = {
          shape: {
            name: 'Upper limit',
            type: 'line',
            xref: 'paper',
            yref: 'y2',
            x0: 0,
            y0: lowerLimit,
            x1: 1,
            y1: lowerLimit,
            line: {
              color: 'orange',
              width: 1,
            },
          },
          annotation: {
            showarrow: false,
            text: `${t('substanceStatusDaily.lowerLimit')} ${lowerLimit}`,
            x: 0.5,
            xref: 'paper',
            xanchor: 'center',
            y: lowerLimit,
            yref: 'y2',
          },
        };

        return [...shapeAndAnnotations, newShapeAndAnnotation];
      },
      []
    );

    return result;
  }, [data.substanceWaterQualityList, t]);

  const config: any = {
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'autoScale2d',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'lasso2d',
      'select2d',
      'toggleSpikelines',
    ],
    displaylogo: false,
  };

  // render the component
  return render();

  function render() {
    return (
      <Plot
        style={{ width: '100%', height: '100%' }}
        data={traces}
        layout={{
          autosize: true,
          showlegend: true,
          margin: { t: 40, b: 20, l: 60, r: 60 },
          yaxis: {
            rangemode: 'nonnegative',
            color: '#8884d8',
            gridwidth: 2,
            ticksuffix: data.precipitationUnit,
            autorange: 'reversed',
            side: 'left',
          },
          yaxis2: {
            gridwidth: 2,
            ticksuffix: data.substanceUnit,
            color: '#6EE387',
            anchor: 'free',
            overlaying: 'y',
            side: 'right',
            position: 1,
          },
          yaxis3: {
            rangemode: 'nonnegative',
            gridwidth: 2,
            ticksuffix: props.waterLevelUnit,
            anchor: 'x',
            overlaying: 'y',
            side: 'right',
            color: '#ffa600',
          },
          xaxis: {
            gridwidth: 2,
            tickformat: '%d/%m/%y',
            domain: [0, 0.9],
          },
          shapes: [
            ...upperLimits.map(item => item.shape),
            ...lowerLimits.map(item => item.shape),
          ],
          annotations: [
            ...upperLimits.map(item => item.annotation),
            ...lowerLimits.map(item => item.annotation),
          ],
          legend: {
            orientation: 'h',
          },
        }}
        config={config}
      />
    );
  }
};

function traceForSourceType(
  source: SourceType,
  data: LocationMeasurementsDaily,
  waterLevelLabel: string
): Partial<PlotData> | null {
  const base = {
    type: 'scatter',
    legendgroup: 'group3',
    mode: 'lines',
    fill: 'tozeroy',
    visible: 'legendonly',
    connectgaps: false,
    marker: { color: '#ffa600' },
    yaxis: 'y3',
    name: waterLevelLabel,
  };

  if (source === SourceType.SURFACE_WATER) {
    if (data.surfaceWaterLevelList) {
      return {
        ...base,
        x: data.surfaceWaterLevelList.map(item => new Date(item.time)),
        y: data.surfaceWaterLevelList.map(item => item.value),
        line: { shape: 'linear' },
      } as Partial<PlotData>;
    } else {
      return null;
    }
  } else {
    if (data.surfaceWaterLevelList) {
      return {
        ...base,
        x: data.groundWaterLevelList.map(item => new Date(item.time)),
        y: data.groundWaterLevelList.map(item => item.value),
        line: { shape: 'spline' },
      } as Partial<PlotData>;
    } else {
      return null;
    }
  }
}
