import * as React from 'react';
import classnames from 'classnames';
import Resizable from 're-resizable';
import Icon from '../icon';
import styles from './window.module.scss';

interface Props {
  className?: string;
  title: string;
  width: number;
  height: number;
  onClose: (e: React.MouseEvent<HTMLDivElement>) => void;
  children?: any;
}

const Window = (props: Props) => {
  const cxContainer = classnames(props.className, styles.window);

  return (
    <Resizable
      defaultSize={{
        width: props.width,
        height: props.height,
      }}
      enable={{ bottom: true }}
      className={cxContainer}
    >
      <div className={styles.header}>
        <span className={styles.title}>{props.title}</span>
        <div className={styles.close} onClick={props.onClose}>
          <Icon
            name="times-circle"
            fill="white"
            // size={16}
            className={styles.icon}
          />
        </div>
      </div>
      <div className={styles.body}>{props.children}</div>
    </Resizable>
  );
};

Window.defaultProps = {
  width: 'auto',
  height: 'auto',
};

export default Window;
