import React from 'react';
import { LocationMeasurementsMonthly } from '../../types';
// @ts-ignore: TODO: insufficient type definition
import Flex from 'styled-flex-component';
import { SubstanceGraphMonthly } from '../graphs/substance-graph-monthly';

export interface Props {
  data: LocationMeasurementsMonthly;
}

export function SubstanceStatusMonthly(props: Props) {
  const { data } = props;
  return render();

  function render() {
    return (
      <Flex column>
        <SubstanceGraphMonthly data={data} />

        <ul>
          {data.substanceWaterQualityList.map(waterQualityItem => (
            <li key={waterQualityItem.substanceDescription.key}>
              {waterQualityItem.substanceDescription.translation}
            </li>
          ))}
        </ul>
      </Flex>
    );
  }
}
