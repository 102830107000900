import * as React from 'react';
import classnames from 'classnames';
import styles from './tabs.module.scss';
import { noop } from 'lodash-es';

export const Tabs = (props: { children: React.ReactNode }) => {
  return <div className={styles.tabs}>{props.children}</div>;
};

interface TabItemProps {
  active?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  onClick: any; // TODO: can this be better typed?
}
export const TabItem = (props: TabItemProps) => {
  function handleClick() {
    if (!props.disabled) props.onClick();
  }

  const cx = classnames(styles.tabItem, {
    [styles.active]: props.active,
    [styles.disabled]: props.disabled,
  });
  return (
    <div className={cx} onClick={handleClick}>
      {props.children}
    </div>
  );
};

TabItem.defaultProps = {
  active: false,
  disabled: false,
  onClick: noop,
};
