import * as React from 'react';
import { noop } from 'lodash-es';
import classnames from 'classnames';
import styles from './input-range.module.scss';

interface Props {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;
  className?: string;
}

interface State {
  value: number;
}
class InputRange extends React.Component<Props, State> {
  static defaultProps = {
    step: 10,
    onChange: noop,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(e.currentTarget.value);
    this.props.onChange(value);
    this.setState({ value });
  }

  render() {
    return (
      <input
        type="range"
        className={classnames(styles.inputRange, this.props.className)}
        value={this.state.value}
        min={this.props.min}
        max={this.props.max}
        step={this.props.step}
        onChange={this.handleChange.bind(this)}
      />
    );
  }
}

export default InputRange;
