import Axios from 'axios';
import { LanguageItem } from '../types';

export async function getLocales(language: LanguageItem): Promise<any> {
  // Add build number to bust browser cache
  const cacheKey = process.env.BUILD_NUMBER || -1;
  const response = await Axios.get(
    `${process.env.PUBLIC_URL}/locales/${language.key}.json?${cacheKey}`
  );
  return response.data;
}
