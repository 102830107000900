import { LanguageItem } from '../types';

export const en = {
  key: 'en-GB',
  translation: 'EN',
};
export const it = {
  key: 'it-IT',
  translation: 'IT',
};
export const nl = {
  key: 'nl-BE',
  translation: 'NL',
};
export const ro = {
  key: 'ro',
  translation: 'RO',
};

export const languages: LanguageItem[] = [en, it, nl, ro];
