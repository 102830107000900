import Axios from 'axios';
import { Standard } from './../types';
import { SourceType } from '../types';
import * as axiosUtils from './../utils/axios.utils';

export async function getStandards(source: SourceType): Promise<Standard[]> {
  const headers = axiosUtils.commonHeaders();
  const response = await Axios.get(`/api/standards`, {
    headers,
    params: { source },
  });
  return response.data;
}
