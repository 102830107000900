import * as React from 'react';
import classnames from 'classnames';
import { noop } from 'lodash-es';
import styles from './styles.module.scss';

interface Props {
  isActive: boolean;
  isDisabled: boolean;
  onClick: Function;
}

class Switch extends React.Component<Props, {}> {
  static defaultProps = {
    onClick: noop,
    isDisabled: false,
  };

  handleClick(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.isDisabled) return;
    this.props.onClick();
  }

  render() {
    const cx = classnames(styles.switch, {
      [styles.isActive]: this.props.isActive,
      [styles.isDisabled]: this.props.isDisabled,
    });
    return (
      <div className={cx} onClick={this.handleClick.bind(this)}>
        <div className={styles.outer}>
          <div className={styles.inner} />
        </div>
      </div>
    );
  }
}

export default Switch;
