import * as React from 'react';
import { Measurement, LocationMeasurementsSoilWaterStatus } from '../../types';
import { useTranslation } from 'react-i18next';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import { PlotData, Layout } from 'plotly.js';
import { format } from 'date-fns';
const Plot = createPlotlyComponent(Plotly);

interface Props {
  data: LocationMeasurementsSoilWaterStatus;
}

const SubstanceGraphSoilWaterStatus = ({ data }: Props) => {
  const { t } = useTranslation();

  const xDataPrecipitation: string[] =
    data.precipitationList &&
    data.precipitationList.map((value: Measurement) => {
      return format(value.time as number, 'yyyy-MM-dd');
    });

  const yDataPrecipitation: number[] =
    data.precipitationList &&
    data.precipitationList.map((value: Measurement) => {
      return value.value;
    });

  const xDataPotentialEvapoTranspiration: string[] =
    data.potentialEvapoTranspirationList &&
    data.potentialEvapoTranspirationList.map((value: Measurement) => {
      return format(value.time as number, 'yyyy-MM-dd');
    });

  const yDataPotentialEvapoTranspiration: number[] =
    data.potentialEvapoTranspirationList &&
    data.potentialEvapoTranspirationList.map((value: Measurement) => {
      return value.value;
    });

  const xDataSoilMoistureDeficit: string[] =
    data.soilMoistureDeficitList &&
    data.soilMoistureDeficitList.map((value: Measurement) => {
      return format(value.time as number, 'yyyy-MM-dd');
    });

  const yDataSoilMoistureDeficit: number[] =
    data.soilMoistureDeficitList &&
    data.soilMoistureDeficitList.map((value: Measurement) => {
      return value.value;
    });

  const plotData: Partial<PlotData>[] = [
    {
      x: xDataPrecipitation,
      y: yDataPrecipitation,
      type: 'bar',
      marker: { color: '#d2e0ea' },
      name: t('soilWaterStatus.precipitation'),
    },
    {
      x: xDataPotentialEvapoTranspiration,
      y: yDataPotentialEvapoTranspiration,
      type: 'bar',
      marker: { color: '#c32d4b' },
      yaxis: 'y',
      name: t('soilWaterStatus.evapotranspiration'),
    },
    {
      x: xDataSoilMoistureDeficit,
      y: yDataSoilMoistureDeficit,
      type: 'scatter',
      mode: 'lines',
      marker: { color: 'black' },
      name: t('soilWaterStatus.soilMoistureDeficit'),
      yaxis: 'y',
    },
  ];

  const layout: Partial<Layout> = {
    title: t('soilWaterStatus.title'),
    margin: {
      b: 80,
    },
    width: 600,
    showlegend: true,
    yaxis: {
      gridwidth: 2,
      ticksuffix: data.precipitationUnit,
    },
    legend: {
      orientation: 'h',
    },
    xaxis: {
      gridwidth: 2,
      tickformat: '%d/%m/%y',
      domain: [0, 0.9],
    },
  };

  const config: any = {
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'autoScale2d',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'lasso2d',
      'select2d',
      'toggleSpikelines',
    ],
    displaylogo: false,
  };

  return (
    <React.Fragment>
      <Plot data={plotData} layout={layout} config={config} />
    </React.Fragment>
  );
};

export default SubstanceGraphSoilWaterStatus;
