import axios from 'axios';
import { auth } from 'marvin-auth-kit';
import { FILE_SIZE_LIMIT } from '../constants';
import { PayloadTooLargeError } from '../errors';
import * as axiosUtils from '../utils/axios.utils';

export async function uploadNewData(
  file: File,
  overwrite: boolean
): Promise<any> {
  try {
    auth.applyInterceptors(axios);
    const headers = axiosUtils.commonHeaders();
    const response = await axios.post(
      `/api/waterquality/import?overwrite=${overwrite}`,
      file,
      {
        headers: {
          ...headers,
          'Content-Type': 'application/octet-stream',
        },
      }
    );

    return response?.data ? response.data : null;
  } catch (ex) {
    if (ex.response.status === 413)
      throw new PayloadTooLargeError(FILE_SIZE_LIMIT);
    throw ex;
  }
}

export async function checkStatus(progressId: number): Promise<any> {
  try {
    const headers = axiosUtils.commonHeaders();
    const response = await axios.get(
      `/api/waterquality/progress/${progressId}`,
      {
        headers,
      }
    );
    return response?.data ? response.data : null;
  } catch (error) {
    throw error;
  }
}
