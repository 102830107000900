import React from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore: TODO: insufficient type definition
import Flex from 'styled-flex-component';
import SubstanceGraphYearly from '../graphs/substance-graph-yearly';
import { LocationMeasurementsYearly, RootState } from '../../types';
import { useSelector } from 'react-redux';

export interface Props {
  data: LocationMeasurementsYearly;
}

function SubstanceStatusYearly(props: Props) {
  const { data } = props;
  const { t } = useTranslation();
  const activeSource: string | undefined = useSelector((state: RootState) => {
    return state.catchments.activeSource?.key;
  });
  return render();

  function render() {
    return (
      <Flex column>
        <SubstanceGraphYearly data={data} />

        <ul>
          {data.substanceWaterQualityList.map(waterQualityItem => (
            <li key={waterQualityItem.substanceDescription.key}>
              {waterQualityItem.substanceDescription.translation}
            </li>
          ))}
          {activeSource === 'surface_water' && (
            <li>{t('substanceStatusYearly.info_surface')}</li>
          )}
          {activeSource === 'ground_water' && (
            <li>{t('substanceStatusYearly.info_ground')}</li>
          )}
        </ul>
      </Flex>
    );
  }
}

export default SubstanceStatusYearly;
