import constants from './constants';
import { WQLGeneralStatus } from '../../types';

export interface State {
  generalStatusData: WQLGeneralStatus[];
  isPending: boolean;
  isError: boolean;
  errorMessage: string;
}

const initialState: State = {
  generalStatusData: [],
  isPending: true,
  isError: false,
  errorMessage: '',
};

interface Action {
  type: string;
  payload: any;
}
const waterQualityLocations = (state = initialState, action: Action): State => {
  switch (action.type) {
    case constants.LOAD_WQL_GENERAL_STATUS_PENDING:
      return {
        ...state,
        isPending: true,
        isError: false,
      };

    case constants.LOAD_WQL_GENERAL_STATUS_FULFILLED:
      return {
        ...state,
        generalStatusData: action.payload,
        isPending: false,
      };

    case constants.LOAD_WQL_GENERAL_STATUS_REJECTED:
      return {
        ...state,
        isPending: false,
        isError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default waterQualityLocations;
