import * as React from 'react';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';

import { LanguageItem } from '../../types';
import styles from './language-selector.module.scss';

interface Props {
  activeLanguage: LanguageItem;
  languages: LanguageItem[];
  onClick: (language: LanguageItem) => any;
}

interface State {
  isOpen: boolean;
}

class LanguageSelector extends React.Component<Props, State> {
  state = {
    isOpen: false,
  };

  toggleOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleClickOutside() {
    this.setState({ isOpen: false });
  }

  handleLanguageClick(language: LanguageItem) {
    this.setState({ isOpen: false });
    this.props.onClick(language);
  }

  render() {
    return (
      //  TODO: maybe make header-item a 'composed' selector in our css-module?
      <div className={`${styles.languageSelector} header-item`}>
        <div
          className="header-item-visible"
          onClick={this.toggleOpen.bind(this)}
        >
          {this.props.activeLanguage.translation}
        </div>
        {this.state.isOpen && (
          <div className="header-item-collapsed">
            {this.props.languages.map(language => {
              const cx = classnames({
                [styles.languageItem]: true,
                [styles.languageItemActive]:
                  language.key === this.props.activeLanguage.key,
              });
              return (
                <div
                  className={cx}
                  key={language.key}
                  onClick={this.handleLanguageClick.bind(this, language)}
                >
                  {language.translation}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default onClickOutside(LanguageSelector);
