import {
  MapState,
  MapActionTypes,
  SET_BASELAYER,
  SET_MAP_POSITION,
  SET_MAP_BOUNDINGBOX,
  SET_LAYER_OPACITY,
  SET_SELECTED_PARCEL_UIDN,
} from './types';

export const initialState: MapState = {
  baselayerKey: 'street-map',
  layerOpacity: 0.5,
  position: {
    lat: 51.00079308917475,
    lng: 4.498901367187501,
    zoom: 9,
  },
  boundingbox: null,
  selectedParcelUidn: null,
};

const mapReducer = (state = initialState, action: MapActionTypes): MapState => {
  switch (action.type) {
    case SET_BASELAYER:
      return {
        ...state,
        baselayerKey: action.payload,
      };

    case SET_LAYER_OPACITY:
      return {
        ...state,
        layerOpacity: action.payload,
      };

    case SET_MAP_POSITION:
      return {
        ...state,
        position: action.payload,
      };

    case SET_MAP_BOUNDINGBOX:
      return {
        ...state,
        boundingbox: action.payload,
      };

    case SET_SELECTED_PARCEL_UIDN:
      return {
        ...state,
        selectedParcelUidn: action.payload,
      };

    default:
      return state;
  }
};

export { mapReducer };
