import React, { useState, Fragment } from 'react';
import styles from './styles.module.scss';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import cx from 'classnames';
import Button from '../../components-marvin/button';
import { auth } from 'marvin-auth-kit';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

interface OwnProps {}

type Props = OwnProps & RouteComponentProps;

const LoginContainer = (props: Props) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const LoginScheme = Yup.object().shape({
    email: Yup.string().required(t('login.emailRequired')),
    password: Yup.string().required(t('login.passwordRequired')),
  });

  const onSubmit = async (values: any, actions: any) => {
    const result = await auth.login(values.email, values.password);
    if (result.isOk()) {
      actions.setSubmitting(false);
      setError(false);
      return props.history.push(`/upload-data`);
    }

    setError(true);
  };

  return (
    <div className={styles.loginContainer}>
      <div className="primary-content-header">
        <h1 className="title">{t('global.login')}</h1>
        <Formik
          initialValues={{
            email: process.env.REACT_APP_LOGIN_EMAIL,
            password: process.env.REACT_APP_LOGIN_PASSWORD,
          }}
          validationSchema={LoginScheme}
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.inputWrapper}>
                <label htmlFor="email" className={styles.inputWrapperLabel}>
                  {t('global.email')}
                </label>
                <Field
                  id="email"
                  type="string"
                  name="email"
                  required
                  className={cx(styles.inputWrapperInput, {
                    isInvalid: errors.email && touched.email,
                  })}
                  placeholder={t('login.enterEmail')}
                />
                <div className={styles.invalidFeedback}>
                  <ErrorMessage name="email" />
                </div>
              </div>
              <div className={styles.inputWrapper}>
                <label htmlFor="password" className={styles.inputWrapperLabel}>
                  {t('global.password')}
                </label>
                <Field
                  id="password"
                  type="password"
                  name="password"
                  required
                  className={cx(styles.inputWrapperInput, {
                    isInvalid: errors.password && touched.password,
                  })}
                  placeholder={t('login.enterPassword')}
                />
                <div className={styles.invalidFeedback}>
                  <ErrorMessage name="password" />
                </div>
              </div>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting === false && (
                  <Fragment>{t('global.login')}</Fragment>
                )}
                {isSubmitting && (
                  <Fragment>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="spinner"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className={styles.spinner}
                    >
                      <path
                        className={styles.spinning}
                        fill="currentColor"
                        d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
                      ></path>
                    </svg>
                    {t('global.processing')}..
                  </Fragment>
                )}
              </Button>
              {error && (
                <p className={styles.invalidCredentials}>
                  {t('login.invalidCredentials')}
                </p>
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default withRouter(LoginContainer);
