import * as React from 'react';
import styles from './button.module.scss';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
}

const Button = (props: Props) => {
  const { onClick, type, children, disabled } = props;
  return (
    <button
      type={type}
      className={styles.button}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;

Button.defaultProps = {
  type: 'button',
  disabled: false,
};
