export const catchmentConfig: {
  name: string;
  countryKey: string;
  displaySoilWaterStatus?: boolean;
  surfaceWaterTranslation: string;
}[] = [
  {
    name: 'Ballycanew',
    countryKey: 'ie',
    displaySoilWaterStatus: true,
    surfaceWaterTranslation: 'Discharge',
  },
  {
    name: 'Bollaertbeek',
    countryKey: 'be',
    surfaceWaterTranslation: 'Level',
  },
  {
    name: 'Castledockerell',
    countryKey: 'ie',
    displaySoilWaterStatus: true,
    surfaceWaterTranslation: 'Discharge',
  },
  {
    name: 'Kleine Kemmelbeek',
    countryKey: 'be',
    surfaceWaterTranslation: 'Level',
  },
  {
    name: 'Mara',
    countryKey: 'ro',
    surfaceWaterTranslation: 'Level',
  },
  {
    name: 'Val Tidone',
    countryKey: 'it',
    surfaceWaterTranslation: 'Level',
  },
];
