import * as legends from './legends';
export { legends };

export const USER_ROLES: string[] = [
  'view',
  'landuse',
  'erosion',
  'export',
  'dataimport',
  'dataimportvito',
  'useradmin',
];

export const DATE_FORMAT = 'D/M/YYYY';

export const FILE_SIZE_LIMIT = '3.5MB';
