import constants from './constants';
import { ReduxAction, LanguageItem } from '../../types';
import { en } from '../../config/languages';

export interface State {
  isMaximized: boolean;
  language: LanguageItem;
}

const initialState: State = {
  isMaximized: false,
  language: en,
};

const preferences = (state = initialState, action: ReduxAction): State => {
  switch (action.type) {
    case constants.SET_MAZIMIZE_PREFENCE:
      return { ...state, isMaximized: action.payload };

    case constants.SET_LANGUAGE:
      return { ...state, language: action.payload };

    default:
      return state;
  }
};

export default preferences;
