import { Catchment, Source, Period } from '../types';

interface Scope {
  activeCatchment: Catchment | undefined;
  activeSource: Source | undefined;
  activePeriod: Period;
}

export function didScopeChange<T extends Scope>(
  prevProps: T,
  props: T
): boolean {
  if (
    prevProps.activeCatchment === undefined ||
    props.activeCatchment === undefined
  )
    return false;
  if (prevProps.activeSource === undefined || props.activeSource === undefined)
    return false;
  const catchmentDidUpdate =
    prevProps.activeCatchment.id !== props.activeCatchment.id;
  const sourceDidUpdate = prevProps.activeSource.key !== props.activeSource.key;
  const periodDidUpdate =
    prevProps.activePeriod.from !== props.activePeriod.from ||
    prevProps.activePeriod.to !== props.activePeriod.to;
  return catchmentDidUpdate || sourceDidUpdate || periodDidUpdate;
}
