/**
 * Redux
 */
import { InitState } from './redux/init/types';
import { MapState } from './redux/map/types';
import { State as CatchmentsState } from './redux/catchments/reducers';
import { State as PeriodState } from './redux/period/reducers';
import { State as WaterQualityLocationsState } from './redux/water-quality-locations/reducers';
import { State as PreferencesState } from './redux/preferences/reducers';

import { Point, LatLng } from 'leaflet';
import { Annotations, Shape } from 'plotly.js';

export interface LanguageItem {
  key: string;
  translation: string;
}

export interface RootState {
  init: InitState;
  map: MapState;
  catchments: CatchmentsState;
  period: PeriodState;
  waterQualityLocations: WaterQualityLocationsState;
  preferences: PreferencesState;
}

/**
 * ...
 */

export interface LeafletLayer {
  url: string;
  layers: string;
  transparent: boolean;
  format: string;
  minZoom: number;
  maxZoom: number;
  style: string;
  buffer?: number;
}

export interface BaseLayer {
  key: string;
  title: string;
  translationKey: string;
  description: string;
  url: string;
  attribution?: string;
  image: string;
}

export interface Period {
  from: number;
  to: number;
}

export interface Location {
  type: string;
  coordinates: [number, number];
}

export interface Catchment {
  id: number;
  name: string;
  country: {
    key: string;
    translation: string;
  };
  layers: CatchmentLayer[];
  sources: Source[];
  imageUrl: string;
  location_4326: Location;
  zoomFactor: number;
  administrators: string[];
}

export interface CatchmentLayer {
  id: number;
  wmsLayer: string;
  wmsLayerPrefix: string;
  name: string;
  namePrefix: string;
  legend: Legend;
  isActive: boolean;
  defaultLayer: boolean;
  sequence: number;
  parcelMap: boolean;
  source?: string;
  sourceUrl?: string;
  variation?: string;
  bmpMap?: boolean;
  tileBuffer_px?: number;
}

export interface Legend {
  key: string;
  title: string;
  entity?: string;
  legendItems: LegendItem[];
}

export enum LegendItemType {
  SOLID_LINE = 'solid_line',
  RECTANGLE = 'rectangle',
  // TODO: add svg type legendItemType, this needs a required 'svg' property then
  // SVG="SVG",
}
interface LegendItem {
  type: LegendItemType;
  color: string;
  label: string;
}

export enum SourceType {
  GROUND_WATER = 'ground_water',
  SURFACE_WATER = 'surface_water',
}
export interface Source {
  key: SourceType;
  translation: string;
}

export interface ReduxAction {
  type: string;
  payload: any;
}

export enum PredefinedPeriod {
  '30_days_ago',
  '6_months_ago',
  '1_year_ago',
}

export interface WQLGeneralStatus {
  id: number;
  code: string;
  description: string;
  generalStatus: GeneralStatus;
  location_4326: {
    type: string;
    coordinates: [number, number]; // long, lat
  };
  name: string;
  passiveSampling: boolean;
  protectedData: boolean;
  percentageExceeded: number;
}

export enum GeneralStatus {
  no_data = 'no_data',
  status_ok = 'status_ok',
  status_exceedance = 'status_exceedance',
}

export interface WQLSubstanceStatus {
  code: string;
  description: string;
  generalStatus: SubstanceGeneralStatus;
  id: 2;
  location_4326: {
    type: string;
    coordinates: [number, number];
  };
  name: 'number';
  nbExceedances: number;
  passiveSampling: boolean;
  percentageExceeded: number;
  protectedData: boolean;
  totalMeasurements: number;
}

export enum SubstanceGeneralStatus {
  no_data = 'no_data',
  no_evaluation = 'no_evaluation',
  status_ok = 'status_ok',
  status_exceedance = 'status_exceedance',
}

export interface WQLNumberExceededSubstances {
  startDateTime: number;
  endDateTime: number;
  id: number;
  code: string;
  name: string;
  description: string;
  protectedData: boolean;
  passiveSampling: boolean;
  nbSubstancesNoData: number;
  nbSubstancesTotal: number;
  nbSubstancesExceedingDrinkingWaterLimit: number;
  nbSubstancesExceedingEnvironmentalLimit: number;
  nbSubstancesNoEvaluationDrinkingWaterLimit: number;
  nbSubstancesNoEvaluationEnvironmentalLimit: number;
  substancesExceedingDrinkingWaterLimit?: [];
  substancesExceedingEnvironmentalLimit?: [];
}

export interface Crop {
  id: number;
  name: string;
  substances: Substance[];
}

export interface Substance {
  id: number;
  symbol: string;
  name: string;
  unit: string;
}

export interface Standard {
  type: {
    key: string;
    translation: string;
  };
}

export interface WaterQualityLocation {
  code: string;
  name: string;
  description: string;
}

export interface LocationMeasurementsDaily {
  substanceName: string;
  substanceSymbol: string;
  substanceUnit: string;
  substanceWaterQualityList: SubstanceWaterQuality[];
  groundWaterLevelLocation: string;
  groundWaterLevelUnit: string;
  groundWaterLevelList: Measurement[];
  surfaceWaterLevelLocation: string;
  surfaceWaterLevelUnit: string;
  surfaceWaterLevelList: Measurement[];
  precipitationLocation: string;
  precipitationUnit: string;
  precipitationList: Measurement[];
}

interface SubstanceWaterQuality {
  substanceName: string;
  substanceSymbol: string;
  substanceLabel: { key: string; translation: string };
  substanceDescription: { key: string; translation: string };
  standard?: {
    id: number;
    type: { key: string; translation: string };
    upperLimit?: number;
    lowerLimit?: number;
  };
  waterQualityList: Measurement[];
}

export interface LocationMeasurementsMonthly {
  substanceUnit: string;
  substanceWaterQualityList: {
    substanceName: string;
    substanceSymbol: string;
    substanceDescription: { key: string; translation: string };
    monthlyAverageWaterQualityList: Measurement[];
    monthlyMaximumWaterQualityList: Measurement[];
    standard?: {
      id: number;
      type: { key: string; translation: string };
      upperLimit?: number;
      lowerLimit?: number;
    };
  }[];
}

export interface LocationMeasurementsYearly {
  substanceWaterQualityList: {
    substanceName: string;
    substanceSymbol: string;
    substanceDescription: { key: string; translation: string };
    annualAverageWaterQualityList: Measurement[];
    standard?: {
      id: number;
      type: { key: string; translation: string };
      upperLimit?: number;
      lowerLimit?: number;
    };
  }[];
  substanceUnit: string;
}

export interface LocationMeasurementsSoilWaterStatus {
  potentialEvapoTranspirationList: Measurement[];
  potentialEvapoTranspirationLocation: string;
  potentialEvapoTranspirationUnit: string;
  soilMoistureDeficitList: Measurement[];
  soilMoistureDeficitLocation: string;
  soilMoistureDeficitUnit: string;
  precipitationList: Measurement[];
  precipitationLocation: string;
  precipitationUnit: string;
}

export interface Measurement {
  id: number;
  time: number;
  value: number;
  unit: string;
  belowReportingLimit?: boolean;
}

export interface MapPosition {
  lat: number;
  lng: number;
  zoom: number;
}

export interface SubstanceGraphData {
  waterQualityData: Measurement[];
  precipitationData: Measurement[];
  waterLevelData: Measurement[];
}

export interface SubstanceUnits {
  substanceUnit: string;
  precipitationUnit: string;
  waterLevelUnit: string;
}

export interface SubstanceLabels {
  waterQualityLabel: string;
  precipitationLabel: string;
  waterLevelLabel: string;
}

export interface LandUseDataElement {
  area_m2: number;
  landUseCategory: { key: string; translation: string };
  year: number;
  color: string;
}

export interface LandUseData {
  landUseList: LandUseDataElement[];
  year: number;
}

export interface ErosionData {
  area_m2: number;
  erosionClass: { key: string; translation: string };
  color: string;
}

export interface MyMapClick {
  x: number;
  y: number;
  mapSize: Point;
  bbox: string;
  position: LatLng;
}

export interface RiskClass {
  id: number;
  code: string;
  riskClassType: string;
  riskClassCategory: string;
}

export interface Parcel {
  id: number;
  code: 'string';
  riskClassList: RiskClass[];
  bmpTypeList: BmpTypeList[];
}

export interface BmpTypeList {
  bmpType: string;
  bmpList: Bmp[];
}

export interface Bmp {
  id: number;
  bmpId: number;
  number: number;
  description: string;
}

export interface BmpInfo {
  id: number;
  number: number;
  description: string;
  pollutants: string[];
  risks: string[];
  imageUrl: string;
  introduction: string;
  benefitsLimitations: string;
  costs: string;
}

export interface ShapeAndAnnotation {
  shape: Partial<Shape>;
  annotation: Partial<Annotations>;
}
