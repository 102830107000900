import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, Parcel, BmpTypeList, RiskClass, Bmp } from '../../types';
import { getParcel } from '../../services/measures.service';
import Icon from '../../components-marvin/icon';
import styles from './styles.module.scss';
import { withRouter, RouteComponentProps } from 'react-router';

interface OwnProps {
  parcelId: number;
}

type Props = OwnProps & RouteComponentProps;

const MeasuresInfo = (props: Props) => {
  const { t } = useTranslation();

  const activeCatchmentId: number = useSelector((state: RootState) => {
    return state.catchments.activeCatchmentId;
  });

  const [parcelData, setParcelData] = useState<Parcel | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  useEffect(() => {
    async function loadParcelData() {
      const parcelData = await getParcel(activeCatchmentId, props.parcelId);
      setParcelData(parcelData);
      const categories: string[] = parcelData.bmpTypeList.map(
        (bmpTypeList: BmpTypeList) => {
          return bmpTypeList.bmpType;
        }
      );
      setCategories(categories);
      setSelectedCategory(categories[0]);
    }
    loadParcelData();
  }, [activeCatchmentId, props.parcelId]);

  const showBmp = (bmpId: number) => {
    props.history.push(`/measures/${bmpId}`);
  };

  return (
    <React.Fragment>
      {parcelData ? (
        <div className={styles.measuresInfo}>
          <div className={styles.header}>
            <h2 className={styles.title}>{t('measuresInfo.lotTitle')}</h2>
            <Icon
              name="map-marker-alt"
              size={16}
              fill={'#3e4960'}
              className={styles.icon}
            />
          </div>
          <div>{parcelData.code}</div>
          <div className={styles.header}>
            <h2 className={styles.title}>{t('measuresInfo.riskTitle')}</h2>
          </div>
          <div>
            {parcelData.riskClassList.map((riskClass: RiskClass) => {
              return (
                <div key={riskClass.id}>
                  <span className={styles.bold}>
                    {riskClass.riskClassType}:{' '}
                  </span>
                  {riskClass.riskClassCategory}
                </div>
              );
            })}
          </div>
          <div className={styles.header}>
            <h2 className={styles.title}>{t('measuresInfo.bmpTitle')}</h2>
          </div>
          {categories.length > 1 ? (
            <p style={{ lineHeight: '1' }}>{t('measuresInfo.categories')}</p>
          ) : (
            <p style={{ color: '#67AC44' }}>{t('measuresInfo.noMeasures')}</p>
          )}
          <select
            className={styles.select}
            value={selectedCategory}
            onChange={event => {
              setSelectedCategory(event.target.value);
            }}
          >
            {categories.map((category: string) => {
              return (
                <option key={category} value={category}>
                  {category}
                </option>
              );
            })}
          </select>
          {}
          <div>
            {parcelData.bmpTypeList.map((bmpTypeList: BmpTypeList) => {
              if (bmpTypeList.bmpType === selectedCategory) {
                return bmpTypeList.bmpList.map((bmp: Bmp) => {
                  return (
                    <p
                      className={styles.option}
                      onClick={() => showBmp(bmp.bmpId)}
                      key={bmp.bmpId}
                    >
                      {bmp.number}. {bmp.description}
                    </p>
                  );
                });
              }
              return '';
            })}
          </div>
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

export default withRouter(MeasuresInfo);
