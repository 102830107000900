import * as React from 'react';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import { divIcon, MarkerCluster, DivIcon } from 'leaflet';
import { Marker as LeafletMarker } from 'react-leaflet';
import color from 'color';
import {
  RootState,
  SubstanceGeneralStatus,
  WQLSubstanceStatus,
  Catchment,
  Source,
  Period,
} from '../../types';
import Marker from './../../components/marker';
import { MarkerType } from './../../components/marker';
import * as waterLocationService from './../../services/water-location.service';
import {
  getActiveCatchment,
  getActiveSource,
  getActivePeriod,
} from '../../selectors';
import { didScopeChange } from '../../utils';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import MarkerClusterGroup from 'react-leaflet-markercluster';

interface OwnProps {
  activeCatchment: Catchment | undefined;
  activeSource: Source | undefined;
  activePeriod: Period;
}

type Props = OwnProps &
  WithTranslation &
  RouteComponentProps<{
    substanceId: any;
    limit: any;
    locationId: any;
    activeType: any;
  }>;

interface State {
  locations: any;
}

class SubstancesOnMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      locations: [],
    };
  }

  handleMarkerClick(location: WQLSubstanceStatus) {
    this.props.history.push(
      `/substance-analysis/limit/${this.props.match.params.limit}/substance/${this.props.match.params.substanceId}/${location.id}`
    );
  }

  componentDidMount() {
    this.loadLocations();
  }

  componentDidUpdate(prevProps: Props) {
    const scopeChanged = didScopeChange(prevProps, this.props);
    const substanceChanged =
      this.props.match.params.substanceId !==
      prevProps.match.params.substanceId;
    const limitChanged =
      this.props.match.params.limit !== prevProps.match.params.limit;

    if (scopeChanged || substanceChanged || limitChanged) {
      this.loadLocations();
    }

    // this.props.match.params.substanceId,
    // this.props.match.params.limit
    // // TODO: improve location related props to trigger update
    // if (prevProps !== this.props) this.loadLocations();
  }

  async loadLocations() {
    if (!this.props.activeCatchment || !this.props.activeSource) return;
    const locations = await waterLocationService.getSubstanceStatuses(
      this.props.activeCatchment.id,
      this.props.activeSource.key,
      this.props.activePeriod,
      this.props.match.params.substanceId,
      this.props.match.params.limit
    );
    this.setState({ locations });
  }

  render() {
    const { locations } = this.state;
    const locationsToIterate: WQLSubstanceStatus[] = locations.substanceStatusList
      ? locations.substanceStatusList
      : locations;
    return (
      <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
        <div>
          {locationsToIterate.map((location: WQLSubstanceStatus) => {
            const color = defineMarkerColor(location);
            const position = [
              location.location_4326.coordinates[1],
              location.location_4326.coordinates[0],
            ];
            return (
              <LeafletMarker
                key={[location.id, location.percentageExceeded].join('-')}
                position={{ lat: position[0], lng: position[1] }}
                onClick={this.handleMarkerClick.bind(this, location)}
                attribution={JSON.stringify(location)}
                icon={divIcon({
                  html: ReactDOMServer.renderToString(
                    <div>
                      <Marker
                        active={
                          parseInt(this.props.match.params.locationId) ===
                          location.id
                        }
                        disabled={
                          location.generalStatus ===
                          SubstanceGeneralStatus.no_data
                        }
                        color={color}
                        markerType={MarkerType.SUBSTANCE}
                      />
                    </div>
                  ),
                })}
              />
            );
          })}
        </div>
      </MarkerClusterGroup>
    );
  }
}

// TODO: map marker color to legend
function defineMarkerColor(substance: WQLSubstanceStatus): string {
  switch (substance.generalStatus) {
    case SubstanceGeneralStatus.no_data:
      return '#D4D4D4';
    case SubstanceGeneralStatus.no_evaluation:
      return '#FFF';
    case SubstanceGeneralStatus.status_ok:
      return '#67AC44';
    case SubstanceGeneralStatus.status_exceedance:
      if (substance.percentageExceeded <= 10) {
        return 'yellow';
      }
      if (substance.percentageExceeded <= 20) {
        return 'orange';
      }
      return 'purple';
    default:
      return '#000';
  }
}

function createClusterCustomIcon(cluster: MarkerCluster): DivIcon {
  const childMarkers = cluster.getAllChildMarkers();
  const locations: WQLSubstanceStatus[] = childMarkers.map(
    marker =>
      marker.options.attribution && JSON.parse(marker.options.attribution)
  );

  const maxLocation = locations.reduce((prev, current) => {
    return prev.percentageExceeded > current.percentageExceeded
      ? prev
      : current;
  });

  const backgroundcolor: string = defineMarkerColor(maxLocation);

  const css = `
    position: absolute;
    width: 34px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    border-radius: 100%;
    background: ${backgroundcolor};
    display: flex;
    color: ${color(backgroundcolor).isDark() ? 'white' : 'black'};
    font-weight: bold;
    font-size: 1.1rem;
    justify-content: center;
    align-items: center;
    background-clip: content-box;
    padding: 0;
    box-sizing: content-box;
    cursor: pointer;`;

  return divIcon({
    html: `<span style="${css}">${cluster.getChildCount()}</span>`,
    className: 'marker-cluster-custom',
  });
}

const mapStateToProps = (state: RootState) => {
  return {
    // substances: state.substanceAnalysis.data,
    activeCatchment: getActiveCatchment(state),
    activeSource: getActiveSource(state),
    activePeriod: getActivePeriod(state),
  };
};

export default connect(mapStateToProps)(SubstancesOnMap);
