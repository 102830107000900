import * as React from 'react';
import styles from './styles.module.scss';
import { CatchmentLayer } from '../../types';
import Tooltip from '../../components-marvin/tooltip';
import Icon from '../../components-marvin/icon';
import Switch from '../../components-marvin/switch';

interface Props {
  layer: CatchmentLayer;
  onClick: (id: number) => void;
}

const LayerToggle = ({ layer, onClick }: Props) => {
  return (
    <div key={layer.id} className={styles.layerItem}>
      <div className={styles.layerItemName}>
        {layer.name}
        {layer.source && (
          <Tooltip position="bottom" text={layer.source} delay={300}>
            <Icon
              name="info-circle"
              size={12}
              fill="#d4d4dd"
              className={styles.sourceInfo}
            />
          </Tooltip>
        )}
      </div>
      <Switch
        isActive={layer.isActive}
        // isDisabled={isLayerDisabled(layer, catchmentLayers)}
        onClick={() => {
          onClick(layer.id);
        }}
      />
    </div>
  );
};

export default LayerToggle;
