import { combineReducers } from 'redux';
import init from './init/reducers';
import { mapReducer } from './map/reducers';
import routingHistory from './routing-history/reducers';
import preferences from './preferences/reducers';
import catchments from './catchments/reducers';
import period from './period/reducers';
import waterQualityLocations from './water-quality-locations/reducers';

const rootReducer = combineReducers({
  init,
  map: mapReducer,
  routingHistory,
  preferences,
  catchments,
  period,
  waterQualityLocations,
});

export default rootReducer;
