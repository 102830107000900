import * as React from 'react';
import { noop } from 'lodash-es';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// @ts-ignore: insufficient type definition, `declare module 'react-day-picker/moment';`
import { formatDate, parseDate } from 'react-day-picker/moment';
import { withTranslation, WithTranslation } from 'react-i18next';
import 'react-day-picker/lib/style.css';
import { DATE_FORMAT } from '../../constants';
import { PredefinedPeriod, Period } from '../../types';
import * as dateUtils from '../../date-utils';

interface Props {
  className?: string;
  selectedPeriod: Period;
  activeDates: Date[];
  onClick: Function;
}

interface State {
  isOpen: boolean;
  applicableDate: {
    from?: Date;
    to?: Date;
  };
}

enum DateField {
  FROM,
  TO,
}

class PeriodSelector extends React.Component<Props & WithTranslation, State> {
  static defaultProps = {
    className: '',
    selectedPeriod: { from: new Date().getTime(), to: new Date().getTime() },
    onClick: noop,
  };

  state = {
    isOpen: false,
    applicableDate: {
      from: new Date(this.props.selectedPeriod.from),
      to: new Date(this.props.selectedPeriod.to),
    },
  };

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleClickOutside() {
    this.setState({ isOpen: false });
  }

  handleDayChange(dateField: DateField, selectedDay: Date): void {
    if (dateField === DateField.FROM) {
      this.setState({
        applicableDate: { ...this.state.applicableDate, from: selectedDay },
      });
    }
    if (dateField === DateField.TO) {
      this.setState({
        applicableDate: { ...this.state.applicableDate, to: selectedDay },
      });
    }
  }

  handleSelectPredefinedPeriod(period: PredefinedPeriod): void {
    const dateRange = dateUtils.defineDateRange(period);

    this.setState({
      applicableDate: { from: dateRange[0], to: dateRange[1] },
    });
  }

  handlePeriodApply() {
    this.props.onClick(this.state.applicableDate);
    this.setState({ isOpen: false });
  }

  render() {
    const { t } = this.props;
    const cx = classnames('period-selector', this.props.className, {
      'is-open': this.state.isOpen,
    });

    const modifiers = {
      availlable: this.props.activeDates,
    };

    return (
      <div className={cx}>
        <div className="header" onClick={this.toggleIsOpen.bind(this)}>
          <svg className="header-icon" viewBox="0 0 448 512">
            <path d="M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm116 204c0-6.6-5.4-12-12-12H76c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40zm0-128c0-6.6-5.4-12-12-12H76c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40zm128 128c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40zm0-128c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40zm128 128c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40zm0-128c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12v-40z" />
          </svg>
          <div className="period-selected">
            {formatDate(this.props.selectedPeriod.from, DATE_FORMAT)}
            {` - `}
            {formatDate(this.props.selectedPeriod.to, DATE_FORMAT)}
          </div>
        </div>

        {this.state.isOpen && (
          <div className="period-collapsable">
            <div className="period-select-predefined">
              <div className="subtitle">{t('periodSelector.titlePeriod')}</div>
              <ul className="predefined-list">
                <li
                  className="predefined-list-item"
                  onClick={this.handleSelectPredefinedPeriod.bind(
                    this,
                    PredefinedPeriod['30_days_ago']
                  )}
                >
                  {t('periodSelector.selectorLast30Days')}
                </li>
                <li
                  className="predefined-list-item"
                  onClick={this.handleSelectPredefinedPeriod.bind(
                    this,
                    PredefinedPeriod['6_months_ago']
                  )}
                >
                  {t('periodSelector.selectorLast6Months')}
                </li>
                <li
                  className="predefined-list-item"
                  onClick={this.handleSelectPredefinedPeriod.bind(
                    this,
                    PredefinedPeriod['1_year_ago']
                  )}
                >
                  {t('periodSelector.selectorLast1Year')}
                </li>
              </ul>
            </div>

            <div className="divider" />

            <div className="period-select-timeframe">
              <div className="subtitle">
                {t('periodSelector.titleTimeframe')}
              </div>
              <div className="date-picker-group">
                <div className="label">{t('periodSelector.labelFrom')}:</div>
                <DayPickerInput
                  format={DATE_FORMAT}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  value={this.state.applicableDate.from}
                  dayPickerProps={{
                    selectedDays: this.state.applicableDate.from,
                    disabledDays: { after: this.state.applicableDate.to },
                    modifiers,
                  }}
                  onDayChange={this.handleDayChange.bind(this, DateField.FROM)}
                />
              </div>

              <div className="date-picker-group">
                <div className="label">{t('periodSelector.labelTo')}:</div>
                <DayPickerInput
                  format={DATE_FORMAT}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  value={this.state.applicableDate.to}
                  dayPickerProps={{
                    selectedDays: this.state.applicableDate.to,
                    disabledDays: { before: this.state.applicableDate.from },
                    modifiers,
                  }}
                  onDayChange={this.handleDayChange.bind(this, DateField.TO)}
                />
              </div>

              <div className="btn-group">
                <div
                  className="btn btn-primary"
                  onClick={this.handlePeriodApply.bind(this)}
                >
                  {t('periodSelector.buttonApply')}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default withTranslation()(onClickOutside(PeriodSelector));
