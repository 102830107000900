import * as React from 'react';
import classnames from 'classnames';
import { Legend as LegendType } from '../../types';
import styles from './legend.module.scss';

interface Props {
  legend: LegendType;
}

class Legend extends React.Component<Props, {}> {
  render() {
    const { legend } = this.props;
    return (
      <div className={styles.legend}>
        <div className={styles.header}>
          <div className={styles.title}>{legend.title}</div>
          {legend.entity && (
            <div className={styles.entity}>
              <span
                dangerouslySetInnerHTML={{
                  __html: legend.entity,
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.scale}>
          {legend.legendItems.map((scaleItem, i) => {
            const cxScaleVisual = classnames({
              [styles[`scaleVisual--${scaleItem.type}`]]: true,
            });
            return (
              <div className={styles.scaleItem} key={`scaleItem${i}`}>
                <div className={styles.scaleVisualContainer}>
                  <div
                    className={cxScaleVisual}
                    style={{ backgroundColor: scaleItem.color }}
                  />
                </div>
                <div className="scale-label">{scaleItem.label}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default Legend;
