import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

interface MapControlGroupProps {
  children: any;
  position: 'TOP_LEFT' | 'TOP_RIGHT' | 'BOTTOM_LEFT' | 'BOTTOM_RIGHT';
}
export const MapControlGroup = (props: MapControlGroupProps) => {
  const cx = classnames(styles.mapControlGroup, {
    [styles.bottomLeft]: props.position === 'BOTTOM_LEFT',
    [styles.bottomRight]: props.position === 'BOTTOM_RIGHT',
    [styles.topLeft]: props.position === 'TOP_LEFT',
    [styles.topRight]: props.position === 'TOP_RIGHT',
  });
  return <div className={cx}>{props.children}</div>;
};

interface MapControlButtonProps {
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  children: any;
}
export const MapControlButton = (props: MapControlButtonProps) => {
  const cx = classnames(styles.mapControlBtn, {
    [styles.disabled]: props.disabled,
  });

  return (
    <div onClick={props.onClick} className={cx}>
      {props.children}
    </div>
  );
};

interface MapControlProps {
  className?: string;
  children: any;
}
export const MapControl = (props: MapControlProps) => {
  return (
    <div className={classnames(styles.mapControl, props.className)}>
      {props.children}
    </div>
  );
};
