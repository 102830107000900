import { Dispatch } from 'redux';

import { Catchment, RootState } from '../../types';
import { constants } from './types';
import { initI18n } from '../../i18n';
import * as localesService from '../../services/locales.service';
import * as catchmentsActions from '../catchments/actions';
import { getActiveCatchment, getActiveSource } from '../../selectors';

// load initial application data
export function load(): any {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch({ type: constants.INIT });

    try {
      dispatch({ type: constants.INIT });

      // load data async
      await Promise.all([
        loadCatchmentData(dispatch, getState),
        loadI18n(dispatch, getState),
      ]);

      dispatch({
        type: constants.INIT_FULFILLED,
      });
    } catch (ex) {
      console.error('Error catched in loadInitialData', ex);
      dispatch({
        type: constants.INIT_REJECTED,
        payload: ex,
      });
    }
  };
}

async function loadI18n(dispatch: Dispatch, getState: () => RootState) {
  const language = getState().preferences.language;
  const locales = await localesService.getLocales(language);
  await initI18n(language, locales);
  return true;
}

async function loadCatchmentData(
  dispatch: Dispatch,
  getState: () => RootState
): Promise<any> {
  // load all catchments
  await dispatch(catchmentsActions.getCatchments());

  const activeCatchment = getActiveCatchment(getState());
  const activeSource = getActiveSource(getState());

  // if no activeCatchment in local storage load first catchment and its first source
  if (!activeCatchment) {
    const catchment: Catchment = getState().catchments.data[0];
    await dispatch(
      catchmentsActions.activateCatchment(catchment, catchment.sources[0])
    );
  } else {
    if (activeSource) {
      await dispatch(
        catchmentsActions.activateCatchment(activeCatchment, activeSource)
      );
    }
  }

  return true;
}
