import * as React from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import '!svg-sprite-loader!./../assets/icons.svg';
// import styled from "styled-components";
import { noop, pick } from 'lodash-es';

interface Props {
  name: string;
  className?: string;
  onClick: (event: React.MouseEvent) => void;
  size?: number;
  fill?: string;
  title?: string;
}

const Icon = (props: Props) => {
  return (
    <svg
      className={`icon icon-${props.name} ${props.className}`}
      onClick={props.onClick}
      style={getStyle(props)}
      {...pick(props, ['title'])}
    >
      <use xlinkHref={`#icons_${props.name}`} />
    </svg>
  );
};

Icon.defaultProps = {
  onClick: noop,
};

export default Icon;

function getStyle(props: Props): any {
  let style = {};
  if (props.size)
    style = { ...style, width: props.size + 'px', height: props.size + 'px' };
  if (props.fill) style = { ...style, fill: props.fill };
  return style;
}
