import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
  RootState,
  WQLSubstanceStatus,
  Catchment,
  Period,
  Source,
  LocationMeasurementsDaily,
  LocationMeasurementsMonthly,
  LocationMeasurementsYearly,
  LocationMeasurementsSoilWaterStatus,
  WaterQualityLocation,
  LandUseData,
  ErosionData,
} from '../../types';
import * as waterLocationService from '../../services/water-location.service';
import {
  getActiveCatchment,
  getActiveSource,
  getActivePeriod,
} from '../../selectors';
import { Tabs, TabItem } from '../../components/tabs';
import { Heading } from '../../components/design-system';
import Icon from '../../components-marvin/icon';
import { SubstanceStatusDaily } from '../../components/substance-status-daily';
import { SubstanceStatusMonthly } from '../../components/substance-status-monthly';

import styles from './styles.module.scss';
import { RouteComponentProps } from 'react-router';
import { SubstanceStatusYearly } from '../../components/substance-status-yearly';
import LandUseGraph from '../../components/graphs/land-use-graph';
import ErosionGraph from '../../components/graphs/erosion-graph';
import SubstanceGraphSoilWaterStatus from '../../components/graphs/substance-graph-soil-water-status';
// @ts-ignore: TODO: insufficient type definition
import Flex from 'styled-flex-component';
import Accordion from '../../components/accordeon';
import { catchmentConfig } from '../../config/catchment-config';

export interface OwnProps {
  substanceStatus: WQLSubstanceStatus;
  activeCatchment: Catchment | undefined;
  activeSource: Source | undefined;
  activePeriod: Period;
}

type Props = OwnProps &
  WithTranslation &
  RouteComponentProps<{ locationId: any; limit: any; substanceId: any }>;

export interface State {
  location: WaterQualityLocation | null;
  dailyData: LocationMeasurementsDaily | null;
  monthlyData: LocationMeasurementsMonthly | null;
  yearlyData: LocationMeasurementsYearly | null;
  landUseData: LandUseData[];
  erosionData: ErosionData[];
  soilWaterStatusData: LocationMeasurementsSoilWaterStatus | null;
  activeTab: TABS;
}

enum TABS {
  Daily,
  Monthly,
  Yearly,
  SoilWaterStatus,
}

class SubstanceStatusContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // dailyData is loaded on component mount because of the WQL name
      // other tabbed data is loaded when clicking the tab...
      location: null,
      dailyData: null,
      monthlyData: null,
      yearlyData: null,
      landUseData: [],
      erosionData: [],
      soilWaterStatusData: null,
      activeTab: TABS.Daily,
    };

    this.loadWQL();
  }

  componentDidMount() {
    this.loadSoilWaterStatusData();
    this.loadMeasurements();
  }

  componentDidUpdate(prevProps: Props) {
    // TODO: improve location related props to trigger update
    if (prevProps !== this.props) {
      this.loadMeasurements();
      this.loadWQL();
    }
  }

  async loadWQL() {
    const location = await waterLocationService.getWaterQualityLocation(
      this.props.match.params.locationId
    );
    this.setState({ location });
  }

  loadMeasurements() {
    if (this.props.activeSource === undefined) return;
    switch (this.state.activeTab) {
      case TABS.Daily:
        this.loadDailyData();
        break;
      case TABS.Monthly:
        this.loadMonthlyData();
        break;
      case TABS.Yearly:
        this.loadYearlyData();
        break;
      case TABS.SoilWaterStatus:
        this.loadSoilWaterStatusData();
        break;
    }
    this.loadLandUseData();
    this.loadErosionData();
  }

  loadDailyData() {
    this.setState({ dailyData: null }, async () => {
      const dailyData = await waterLocationService.getMeasurementsDaily(
        this.props.match.params.locationId,
        this.props.activeSource!.key,
        this.props.activePeriod,
        this.props.match.params.substanceId,
        this.props.match.params.limit
      );

      this.setState({ dailyData });
    });
  }

  loadMonthlyData() {
    this.setState({ monthlyData: null }, async () => {
      const monthlyData = await waterLocationService.getMeasurementsMonthly(
        this.props.match.params.locationId,
        this.props.activeSource!.key,
        this.props.activePeriod,
        this.props.match.params.substanceId,
        this.props.match.params.limit
      );

      this.setState({ monthlyData });
    });
  }

  loadYearlyData() {
    this.setState({ yearlyData: null }, async () => {
      const yearlyData = await waterLocationService.getMeasurementsYearly(
        this.props.match.params.locationId,
        this.props.activeSource!.key,
        this.props.activePeriod,
        this.props.match.params.substanceId
      );

      this.setState({ yearlyData });
    });
  }

  loadSoilWaterStatusData() {
    this.setState(
      { soilWaterStatusData: this.state.soilWaterStatusData },
      async () => {
        const soilWaterStatusData = await waterLocationService.getMeasurementsSoilWaterStatus(
          this.props.match.params.locationId,
          this.props.activePeriod
        );

        this.setState({ soilWaterStatusData });
      }
    );
  }

  loadLandUseData() {
    this.setState({ landUseData: [] }, async () => {
      const landUseData = await waterLocationService.getLandUseData(
        this.props.match.params.locationId
      );

      this.setState({ landUseData });
    });
  }

  loadErosionData() {
    this.setState({ erosionData: [] }, async () => {
      const erosionData = await waterLocationService.getErosionData(
        this.props.match.params.locationId,
        this.props.activePeriod
      );

      this.setState({ erosionData });
    });
  }

  handleClose() {
    this.props.history.push(
      `/substance-analysis/limit/${this.props.match.params.limit}/substance/${this.props.match.params.substanceId}`
    );
  }

  handleTabClick(tab: TABS) {
    this.setState({ activeTab: tab }, () => {
      this.loadMeasurements();
    });
  }

  shouldDisplaySoilWaterStatusTab() {
    const activeConfig = catchmentConfig.filter(catchmentConfig => {
      return (
        catchmentConfig.countryKey ===
          this.props.activeCatchment?.country.key &&
        catchmentConfig.name === this.props.activeCatchment.name
      );
    });
    return (
      activeConfig && activeConfig[0] && activeConfig[0].displaySoilWaterStatus
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className={styles.container}>
        {this.state.location && (
          <React.Fragment>
            <div className={styles.header}>
              <Heading.h2>
                {this.state.location
                  ? `${this.state.location.name} ${this.state.location.code}`
                  : '...'}
              </Heading.h2>
              <Icon
                name="times-light"
                onClick={this.handleClose.bind(this)}
                className={styles.headerIcon}
              />
            </div>
            <div className={styles.body}>
              <Accordion
                defaultOpenSections={[0]}
                items={[
                  {
                    label: t('substanceStatusAccordion.waterQualityTitle'),
                    content: (
                      <React.Fragment>
                        <Tabs>
                          <TabItem
                            onClick={() => this.handleTabClick(TABS.Daily)}
                            active={this.state.activeTab === TABS.Daily}
                          >
                            {t('substanceStatus.daily')}
                          </TabItem>
                          <TabItem
                            onClick={() => this.handleTabClick(TABS.Monthly)}
                            active={this.state.activeTab === TABS.Monthly}
                          >
                            {t('substanceStatus.monthly')}
                          </TabItem>
                          <TabItem
                            onClick={() => this.handleTabClick(TABS.Yearly)}
                            active={this.state.activeTab === TABS.Yearly}
                          >
                            {t('substanceStatus.yearly')}
                          </TabItem>
                          {this.state.soilWaterStatusData &&
                            this.shouldDisplaySoilWaterStatusTab() && (
                              <TabItem
                                onClick={() =>
                                  this.handleTabClick(TABS.SoilWaterStatus)
                                }
                                active={
                                  this.state.activeTab === TABS.SoilWaterStatus
                                }
                              >
                                {t('substanceStatus.soilWaterStatus')}
                              </TabItem>
                            )}
                        </Tabs>
                        {this.renderActiveTabContent()}
                      </React.Fragment>
                    ),
                  },
                  {
                    label: t('substanceStatusAccordion.landUseTitle'),
                    content: this.renderLandUseChart(),
                  },
                  {
                    label: t('substanceStatusAccordion.erosionTitle'),
                    content: this.renderErosionChart(),
                  },
                ]}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  renderLandUseChart() {
    if (!this.state.landUseData.length) {
      return this.props.t('substanceAnalysis.noDataAvaillable');
    }
    return <LandUseGraph data={this.state.landUseData} />;
  }

  renderErosionChart() {
    return <ErosionGraph data={this.state.erosionData} />;
  }

  renderActiveTabContent() {
    switch (this.state.activeTab) {
      case TABS.Daily:
        return this.renderDaily();
      case TABS.Monthly:
        return this.renderMonthly();
      case TABS.Yearly:
        return this.renderYearly();
      case TABS.SoilWaterStatus:
        return this.renderSoilWaterStatus();
      default:
        return null;
    }
  }

  renderDaily() {
    if (!this.state.dailyData) return;
    return (
      <SubstanceStatusDaily
        data={this.state.dailyData}
        source={this.props.activeSource!.key}
      />
    );
  }

  renderMonthly() {
    if (!this.state.monthlyData) return;
    return (
      <React.Fragment>
        <SubstanceStatusMonthly data={this.state.monthlyData} />
      </React.Fragment>
    );
  }

  renderYearly() {
    if (!this.state.yearlyData) return;
    return (
      <React.Fragment>
        <SubstanceStatusYearly data={this.state.yearlyData} />
      </React.Fragment>
    );
  }

  renderSoilWaterStatus() {
    if (!this.state.soilWaterStatusData) return;
    return (
      <React.Fragment>
        <Flex column>
          <SubstanceGraphSoilWaterStatus
            data={this.state.soilWaterStatusData}
          />
        </Flex>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: Props) => {
  return {
    activeCatchment: getActiveCatchment(state),
    activeSource: getActiveSource(state),
    activePeriod: getActivePeriod(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(withTranslation()(SubstanceStatusContainer))
);
