import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './styles.module.scss';
import Icon from '../../components-marvin/icon';
// @ts-ignore: TODO: add custom module definition
import Flex, { FlexItem } from 'styled-flex-component';
import Button from '../../components-marvin/button';
import { useTranslation } from 'react-i18next';
import { uploadNewData, checkStatus } from '../../services/upload-data.service';
import { LoadingSpinner } from '../../components/loading-spinner';

interface Props {}

interface Error {
  key: string;
  message: string;
  arguments: string[];
}

export default function UploadDataContainer(props: any) {
  const { t } = useTranslation();
  const [file, setFile] = useState<File[] | null>(null);
  const [status, setStatus] = useState<string>('');
  const [errors, setErrors] = useState<Error[]>([]);
  const [message, setMessage] = useState<string>('');
  const [overwrite, setOverwrite] = useState<boolean>(false);

  const onDrop = useCallback(acceptedFiles => {
    setFile(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  async function startImport(selectedFiles: File[] | null) {
    if (selectedFiles) {
      try {
        setStatus('importing');
        const progressId: number = await uploadNewData(
          selectedFiles[0],
          overwrite
        );
        pollStatus(progressId);
      } catch (error) {
        setStatus('failed');
        setErrors([{ key: error.name, message: error.message, arguments: [] }]);
      }
    }
  }

  function pollStatus(progressId: number) {
    const interval = setInterval(async () => {
      const status = await checkStatus(progressId);
      setStatus(status.status.key);
      setErrors(status.errors);
      setMessage(status.message);
      if (status.status.key !== 'importing') {
        clearInterval(interval);
      }
    }, 1000);
  }

  return render();

  function render() {
    return (
      <div style={{ padding: '20px', width: '50%' }}>
        <h3>{t('uploadData.title')}</h3>
        <div {...getRootProps()} className={styles.uploadSection}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <div className={styles.uploadInfo}>{t('uploadData.dropFiles')}</div>
          ) : (
            <div>
              {!file ? (
                <div className={styles.uploadInfo}>
                  <Icon size={26} name="upload" fill={'#3e4960'} />
                  <span>{t('uploadData.dragOrBrowse')}</span>
                </div>
              ) : (
                <>
                  <Icon
                    name="times"
                    size={26}
                    className={styles.close}
                    onClick={event => {
                      event.stopPropagation();
                      setFile(null);
                    }}
                  />
                  <div className={styles.uploadInfo}>
                    <Icon size={26} name="list-alt" fill={'#3e4960'} />
                    <span>
                      {t('uploadData.selectedFile')} {file[0].name}
                    </span>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <p>{t('uploadData.fileFormat')}</p>

        <a href={`/download/templates/waterQuality.xlsx`}>
          {t('uploadData.templateFile')}
        </a>

        <FlexItem alignEnd>
          <Flex justifyEnd className={styles.actions}>
            <Flex row alignCenter>
              <label className={styles.checkbox}>
                <input
                  type="checkbox"
                  checked={overwrite}
                  onChange={event => setOverwrite(event.target.checked)}
                />
                {t('uploadData.overwrite')}
              </label>
            </Flex>
            <Button
              onClick={() => {
                startImport(file);
              }}
            >
              {t('uploadData.startImport')}
            </Button>
          </Flex>
        </FlexItem>
        <FlexItem className={styles.uploadResult}>
          <Flex>
            {status === 'importing' ? (
              <LoadingSpinner textAlign={'right'} />
            ) : (
              ''
            )}
            {status === 'finished' ? (
              <div>
                <div
                  className={styles.uploadResultInfo}
                  style={{ color: '#67ac44' }}
                >
                  <Icon
                    name="check"
                    className={styles.icon}
                    size={26}
                    fill={'#67ac44'}
                  />
                  {t('uploadData.importCompleted')}
                </div>
                <div className={styles.uploadExtraInfoSection}>{message}</div>
              </div>
            ) : (
              ''
            )}
            {status === 'failed' ? (
              <div>
                <div
                  className={styles.uploadResultInfo}
                  style={{ color: '#C30000' }}
                >
                  <Icon
                    name="exclamation-triangle"
                    className={styles.icon}
                    size={26}
                    fill={'#C30000'}
                  />
                  {t('uploadData.importFailed')}
                </div>
                <div className={styles.uploadExtraInfoSection}>
                  {t('uploadData.encounteredErrors')}
                  {errors.length > 0
                    ? errors.map((error: Error) => {
                        return (
                          <div key={error.key}>
                            {insertInString(error.message, error.arguments)}
                          </div>
                        );
                      })
                    : ''}
                </div>
              </div>
            ) : (
              ''
            )}
          </Flex>
        </FlexItem>
      </div>
    );
  }
}

function insertInString(
  originalString: string,
  argumentArray: string[]
): string {
  let adjustedString: string = originalString;

  argumentArray.forEach((argument: string, index: number) => {
    const parts = adjustedString.split(`{${index}}`);
    adjustedString = `${parts[0]}${argument}${parts[1]}`;
  });
  return adjustedString;
}
