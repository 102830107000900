import React, { useState } from 'react';
import { LandUseData, LandUseDataElement } from '../../types';
import Plotly from 'plotly.js-basic-dist';
import styles from './land-use-graph.module.scss';
import createPlotlyComponent from 'react-plotly.js/factory';
import { PlotData, Layout } from 'plotly.js';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
const Plot = createPlotlyComponent(Plotly);

interface Props {
  data: LandUseData[];
}

const LandUsegraph = ({ data }: Props) => {
  const { t } = useTranslation();
  const [selectedData, setSelectedData] = useState<LandUseData>(
    data[data.length - 1]
  );

  const labels: string[] = selectedData.landUseList.map(
    (landUseElement: LandUseDataElement) => {
      return landUseElement.landUseCategory.translation;
    }
  );

  const colors: string[] = selectedData.landUseList.map(
    (landUseElement: LandUseDataElement) => {
      return landUseElement.color;
    }
  );

  const values: number[] = selectedData.landUseList.map(
    (landUseElement: LandUseDataElement) => {
      return landUseElement.area_m2;
    }
  );

  const years: number[] = data.map((landUseData: LandUseData) => {
    return landUseData.year;
  });

  const selectYear = (year: number, dataToFilter: LandUseData[]) => {
    const selectedYearData: LandUseData = dataToFilter.filter(
      (landUseData: LandUseData) => {
        return landUseData.year === year;
      }
    )[0];
    setSelectedData(selectedYearData);
  };

  const plotData: Partial<PlotData>[] = [
    {
      values,
      labels,
      type: 'pie',
      marker: {
        colors,
      },
    },
  ];

  const layout: Partial<Layout> = {
    width: 720,
  };

  const config: any = {
    modeBarButtonsToRemove: [
      'sendDataToCloud',
      'autoScale2d',
      'hoverClosestCartesian',
      'hoverCompareCartesian',
      'lasso2d',
      'select2d',
      'toggleSpikelines',
    ],
    displaylogo: false,
  };

  return (
    <React.Fragment>
      {data[data.length - 1].landUseList.length ? (
        <React.Fragment>
          <Plot
            style={{ margin: '0 0 0 -100px' }}
            data={plotData}
            layout={layout}
            config={config}
          />
          <div className={styles.yearSelection}>
            {years.map((year: number, index: number) => {
              return (
                <span
                  key={year}
                  className={classnames(
                    styles.year,
                    year === selectedData.year && styles.active
                  )}
                  onClick={() => {
                    selectYear(year, data);
                  }}
                >
                  <span>{year}</span>
                </span>
              );
            })}
          </div>
        </React.Fragment>
      ) : (
        <p>{t('landUse.noData')}</p>
      )}
    </React.Fragment>
  );
};

export default LandUsegraph;
