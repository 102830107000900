import { Catchment, Source } from '../../types';

export const SET_ACTIVE_CATCHMENT = 'SET_ACTIVE_CATCHMENT';
export const LOAD_CATCHMENTS = 'LOAD_CATCHMENTS';
export const LOAD_CATCHMENTS_PENDING = 'LOAD_CATCHMENTS_PENDING';
export const LOAD_CATCHMENTS_REJECTED = 'LOAD_CATCHMENTS_REJECTED';
export const LOAD_CATCHMENTS_FULFILLED = 'LOAD_CATCHMENTS_FULFILLED';
export const TOGGLE_ACTIVE_LAYER = 'TOGGLE_ACTIVE_LAYER';
export const SET_ACTIVE_PARCEL_LAYER = 'SET_ACTIVE_PARCEL_LAYER';

interface SetActiveCatchment {
  type: typeof SET_ACTIVE_CATCHMENT;
  payload: {
    catchment: Catchment;
    source: Source;
  };
}

interface LoadCatchments {
  type: typeof LOAD_CATCHMENTS;
  payload: any;
}

interface LoadCatchmentsPending {
  type: typeof LOAD_CATCHMENTS_PENDING;
  payload: any;
}

interface LoadCatchmentsRejected {
  type: typeof LOAD_CATCHMENTS_REJECTED;
  payload: any;
}

interface LoadCatchmentsFulfilled {
  type: typeof LOAD_CATCHMENTS_FULFILLED;
  payload: any;
}

interface ToggleActiveLayer {
  type: typeof TOGGLE_ACTIVE_LAYER;
  payload: any;
}

interface SetActiveParcelLayer {
  type: typeof SET_ACTIVE_PARCEL_LAYER;
  payload: any;
}

export type CatchmentActionTypes =
  | SetActiveCatchment
  | LoadCatchments
  | LoadCatchmentsPending
  | LoadCatchmentsRejected
  | LoadCatchmentsFulfilled
  | ToggleActiveLayer
  | SetActiveParcelLayer;
