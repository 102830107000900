import constants from './constants';

const initialState = {
  layersPath: false,
};

const routingHistory = (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.SET_LAYERS_PATH:
      return {
        ...state,
        layersPath: action.path,
      };

    default:
      return state;
  }
};

export default routingHistory;
