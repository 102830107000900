import * as React from 'react';
import classnames from 'classnames';
import styles from './radio-button.module.scss';
import { noop } from 'lodash-es';

interface Props {
  active: boolean;
  onClick: () => void;
  children?: React.ReactNode;
}

const RadioButton = (props: Props) => {
  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    props.onClick();
  }
  const cx = classnames({
    [styles.radioButton]: true,
    [styles.radioButtonActive]: props.active,
  });
  return (
    <button className={cx} onClick={handleClick}>
      {props.children}
    </button>
  );
};

RadioButton.defaultProps = {
  onClick: noop,
};
export default RadioButton;
