import { LatLngBoundsExpression } from 'leaflet';
import { MapPosition } from '../../types';

export const SET_BASELAYER = 'SET_BASELAYER';
export const SET_LAYER_OPACITY = 'SET_LAYER_OPACITY';
export const SET_MAP_POSITION = 'SET_MAP_POSITION';
export const SET_MAP_BOUNDINGBOX = 'SET_MAP_BOUNDINGBOX';
export const SET_SELECTED_PARCEL_UIDN = 'SET_SELECTED_PARCEL_UIDN';

export interface MapState {
  baselayerKey: string;
  layerOpacity: number;
  position: MapPosition;
  boundingbox: LatLngBoundsExpression | null;
  selectedParcelUidn: number | null;
}

interface SetBaseLayerAction {
  type: typeof SET_BASELAYER;
  // TODO: refactor string into BaseLayer type
  payload: string;
}

interface SetLayerOpacityAction {
  type: typeof SET_LAYER_OPACITY;
  payload: number;
}

interface SetMapPositionAction {
  type: typeof SET_MAP_POSITION;
  payload: MapPosition;
}

interface SetMapBoundingboxAction {
  type: typeof SET_MAP_BOUNDINGBOX;
  payload: LatLngBoundsExpression;
}

interface SetSelectedParcelUidnAction {
  type: typeof SET_SELECTED_PARCEL_UIDN;
  payload: number;
}

export type MapActionTypes =
  | SetBaseLayerAction
  | SetLayerOpacityAction
  | SetMapPositionAction
  | SetMapBoundingboxAction
  | SetSelectedParcelUidnAction;
