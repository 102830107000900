import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LanguageItem } from './types';

export function initI18n(language: LanguageItem, resources: any) {
  i18n.use(initReactI18next).init({
    resources: { [language.key]: resources },
    lng: language.key,
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
  });
}

export { i18n };
