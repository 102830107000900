import React from 'react';
import classNames from 'classnames';
import styles from './tooltip.module.scss';

interface Props {
  text: string;
  secondaryText?: string;
  arrow?: boolean;
  position?: 'left' | 'right' | 'top' | 'bottom';
  children?: JSX.Element;
  delay: number;
}

// TODO: currently the tooltip is an expanding type,
//   this means it blows itself up to the dimensions of the parent element
//   a second type being a wrapper might be usefull too.
const Tooltip = (props: Props) => {
  const { position, arrow } = props;

  const cx = classNames(styles.wrapper, {});

  const toolTipCx = classNames(styles.tooltipText, {
    [styles.arrow]: arrow === true,
    [styles.tooltipLeft]: position === 'left',
    [styles.tooltipRight]: position === 'right',
    [styles.tooltipTop]: position === 'top',
    [styles.tooltipBottom]: position === 'bottom',
  });

  return (
    <div className={cx}>
      <div
        className={toolTipCx}
        style={{ transitionDelay: `${props.delay}ms` }}
      >
        <span>{props.text}</span>
        {props.secondaryText && (
          <React.Fragment>
            <br></br>
            <span>{props.secondaryText}</span>
          </React.Fragment>
        )}
      </div>
      {props.children}
    </div>
  );
};

Tooltip.defaultProps = {
  position: 'left',
  arrow: true,
  delay: 0,
};

export default Tooltip;
