import { MigrationManifest, PersistedState } from 'redux-persist';
import { initialState as initialPeriodState } from './../redux/period/reducers';
import { initialState as initialMapState } from './../redux/map/reducers';

const migrations: MigrationManifest = {
  0: (state: PersistedState): any => {
    return {};
  },
  1: (state: PersistedState): any => {
    return {
      ...state,
      period: initialPeriodState,
    };
  },
  2: (state: PersistedState): any => {
    return {
      ...state,
      map: initialMapState,
    };
  },
};

export default migrations;
