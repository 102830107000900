import * as React from 'react';
import { noop } from 'lodash-es';
import onClickOutside from 'react-onclickoutside';
import Icon from '../components-marvin/icon';
import classnames from 'classnames';
import { Catchment, Source } from '../types';

interface Props {
  className?: string;
  catchments: Catchment[];
  activeCatchment: Catchment;
  activeSource: Source;
  onClick: (catchment: Catchment, source: Source) => void;
}

interface State {
  isOpen: boolean;
}

class CatchmentSelector extends React.Component<Props, State> {
  static defaultProps = {
    className: '',
    catchments: [],
    onClick: noop,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  handleClickOutside() {
    this.setState({ isOpen: false });
  }

  handleCatchmentSelect(catchment: Catchment, source: Source) {
    this.setState({ isOpen: false });
    this.props.onClick(catchment, source);
  }

  render() {
    const { activeCatchment, activeSource } = this.props;
    const cx = classnames('catchment-selector', this.props.className, {
      'is-open': this.state.isOpen,
    });

    const catchmentTypeCx = classnames('catchment-type', {
      'type-surface-water': activeSource.key === 'surface_water',
      'type-ground-water': activeSource.key === 'ground_water',
    });

    return (
      <div className={cx}>
        <div className="header" onClick={this.toggleIsOpen.bind(this)}>
          <Icon name="globe-africa" className="header-icon" />
          <div className="catchment-name">
            {activeCatchment.name}{' '}
            <span className="country">
              {activeCatchment.country.translation}
            </span>
          </div>
          <div className={catchmentTypeCx}>{activeSource.translation}</div>
        </div>

        {this.state.isOpen && this.renderOpen()}
      </div>
    );
  }

  renderOpen() {
    const { activeCatchment, activeSource, catchments } = this.props;
    return (
      <div className="catchment-collapsable">
        <div className="catchment-list">
          {catchments.map((catchment: Catchment, i) => {
            return catchment.sources.map((source, j) => {
              const isActive =
                catchment.id === activeCatchment.id &&
                source.key === activeSource.key;
              return (
                <CatchmentItem
                  className="catchment-item"
                  key={`catchment-item-${i}-source-${j}`}
                  catchment={catchment}
                  source={source}
                  isActive={isActive}
                  onClick={this.handleCatchmentSelect.bind(
                    this,
                    catchment,
                    source
                  )}
                />
              );
            });
          })}
        </div>

        {/* <div className="footer">
          <a className="map-action" href="">
            View on map
          </a>
        </div> */}
      </div>
    );
  }
}

export default onClickOutside(CatchmentSelector);

interface CatchmentItemProps {
  catchment: Catchment;
  source: Source;
  className?: string;
  isActive: boolean;
  onClick: () => void;
}

class CatchmentItem extends React.Component<CatchmentItemProps> {
  static defaultProps = { className: '' };

  render() {
    const { catchment, source } = this.props;
    const catchmentCx = classnames(this.props.className, {
      'is-active': this.props.isActive,
    });
    const catchmentTypeCx = classnames('catchment-type', {
      'type-surface-water': source.key === 'surface_water',
      'type-ground-water': source.key === 'ground_water',
    });

    return (
      <div className={catchmentCx} onClick={() => this.props.onClick()}>
        <img alt="waterprotect" className="preview" src={catchment.imageUrl} />
        <div className="description">
          <div className="name">{catchment.name}</div>
          <div className="country">{catchment.country.translation}</div>
        </div>
        <div className={catchmentTypeCx}>{source.translation}</div>
      </div>
    );
  }
}
