import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore: TODO: add custom module definition
import Flex, { FlexItem } from 'styled-flex-component';

import { Crop, Standard, Substance } from '../../types';
import { Heading } from '../../components/design-system';
import Button from '../../components-marvin/button';
import styles from './styles.module.scss';
import { find } from 'lodash-es';
import RadioButton from '../../components-marvin/radio-button';

interface Props {
  // TODO: type this better
  pesticides: any[];
  standards: Standard[];
  onSubmit: (substance: Substance, standard: Standard) => void;
}

export function PesticidePane(props: Props) {
  const { pesticides: allPesticides, standards: allStandards } = props;

  const { t } = useTranslation();

  const [substance, setSubstance] = useState<Substance | null>(null);
  const [standard, setStandard] = useState<Standard>(allStandards[0]);
  const [crop, setCrop] = useState<Crop>(allPesticides[0]);

  /**
   * Memo
   */
  const substances = useMemo(() => {
    if (crop) return crop.substances;
    else return [];
  }, [crop]);

  /**
   * Effects
   */
  useEffect(
    function autoSelectPesticide() {
      if (allPesticides.length > 0 && crop === null) {
        setCrop(allPesticides[0]);
      }
    },
    [allPesticides, crop]
  );

  useEffect(
    function autoSelectSubstance() {
      if (crop && substance === null) {
        setSubstance(crop.substances[0]);
      }
    },
    [crop, substance]
  );

  /**
   * Functions
   */
  function handleSubstanceChange(e: React.FormEvent<HTMLSelectElement>) {
    const substance = find(substances, {
      id: parseInt(e.currentTarget.value),
    });
    setSubstance(substance || null);
  }

  function handleCropChange(e: React.FormEvent<HTMLSelectElement>) {
    const newCrop = find(allPesticides, {
      id: parseInt(e.currentTarget.value),
    });
    if (newCrop) {
      setCrop(newCrop);
      setSubstance(newCrop.substances[0]);
    }
  }

  function handleSubmit() {
    if (!substance) return;
    if (!standard) return;
    props.onSubmit(substance, standard);
  }

  // render the component
  return render();

  function render() {
    if (allPesticides.length === 0) return renderNoData();
    else return renderForm();
  }

  function renderNoData() {
    return (
      <Flex full column justifyBetween>
        <FlexItem>
          <p>{t('substanceAnalysis.noDataAvaillable')}</p>
        </FlexItem>
      </Flex>
    );
  }

  function renderForm() {
    return (
      <Flex full column justifyBetween>
        <FlexItem>
          <Heading.h2 className={styles.h2}>
            {t('substanceAnalysis.titleCrops')}
          </Heading.h2>

          <select
            value={crop?.id || undefined}
            onChange={event => {
              handleCropChange(event);
            }}
          >
            {allPesticides.map((crop: Crop) => {
              return (
                <option key={crop.id} value={crop.id}>
                  {crop.name}
                </option>
              );
            })}
          </select>

          <Heading.h2 className={styles.h2}>
            {t('substanceAnalysis.titlePesticides')}
          </Heading.h2>

          <select value={substance?.id || ''} onChange={handleSubstanceChange}>
            {crop &&
              crop.substances.map((substance: Substance) => {
                return (
                  <option key={substance.id} value={substance.id}>
                    {substance.name}
                  </option>
                );
              })}
          </select>

          <Heading.h2 className={styles.h2}>
            {t('substanceAnalysis.titleCompareAgainst')}
          </Heading.h2>

          {allStandards.map((item: Standard) => {
            return (
              <div
                key={item.type.key}
                className={styles.limitItem}
                onClick={() => setStandard(item)}
              >
                <RadioButton active={standard?.type.key === item.type.key} />
                <span className="label">{item.type.translation}</span>
              </div>
            );
          })}
        </FlexItem>

        <FlexItem alignEnd>
          <Flex justifyEnd>
            <Button onClick={handleSubmit}>
              {t('substanceAnalysis.actionApply')}
            </Button>
          </Flex>
        </FlexItem>
      </Flex>
    );
  }
}
