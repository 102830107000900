import constants from './constants';
import { ReduxAction, LanguageItem } from './../../types';
import { Dispatch } from 'redux';

export function setLanguage(language: LanguageItem): any {
  return (dispatch: Dispatch) => {
    dispatch({
      type: constants.SET_LANGUAGE,
      payload: language,
    });

    // INFO: timeout is necessary for delay in persisting state in Edge browser
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
}

export function setIsMaximized(isMaximized: boolean): ReduxAction {
  return {
    type: constants.SET_MAZIMIZE_PREFENCE,
    payload: isMaximized,
  };
}
