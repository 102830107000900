import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// @ts-ignore: TODO: insufficient type definition
import Flex from 'styled-flex-component';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
  RootState,
  Catchment,
  Period,
  Source,
  WQLNumberExceededSubstances,
  Substance,
} from '../../types';
import * as waterLocationService from '../../services/water-location.service';
import {
  getActiveCatchment,
  getActiveSource,
  getActivePeriod,
} from '../../selectors';
import {
  PrimaryContentContainer,
  PrimaryContentHeader,
  PrimaryContentBody,
} from '../../components/primary-content';
import { Heading, Paragraph } from '../../components/design-system';
import Icon from '../../components-marvin/icon';
import styled from 'styled-components';
import { didScopeChange } from '../../utils';
import Pauge from '../../components/pauge';

import styles from './styles.module.scss';
import { RouteComponentProps } from 'react-router';

// TODO: refactor into own file
const PrimaryContentHeaderIcon = styled(Icon)`
  position: relative;
  width: 20px;
  height: 20px;
  fill: #3e4960;
  cursor: pointer;
`;

export interface OwnProps {
  activeCatchment: Catchment | undefined;
  activeSource: Source | undefined;
  activePeriod: Period;
}
type Props = OwnProps &
  WithTranslation &
  RouteComponentProps<{ locationId: any }>;

export interface State {
  data?: WQLNumberExceededSubstances;
  dataIsLoading: boolean;
}

class GeneralStatusContainer extends React.Component<
  Props & WithTranslation,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { dataIsLoading: false };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: Props) {
    const locationChanged =
      this.props.match.params.locationId !== prevProps.match.params.locationId;
    const scopeChanged = didScopeChange<Props>(prevProps, this.props);
    if (scopeChanged || locationChanged) this.loadData();
  }

  async loadData() {
    if (this.props.activeSource) {
      this.setState({ dataIsLoading: true });
      const data: WQLNumberExceededSubstances = await waterLocationService.getNumberExceededSubstances(
        this.props.match.params.locationId,
        this.props.activeSource.key,
        this.props.activePeriod
      );
      this.setState({
        data,
        dataIsLoading: false,
      });
    }
  }

  handleClose() {
    this.props.history.push(`/water-quality-locations`);
  }

  goToSubstanceDetails(substance: Substance, limit: string) {
    this.props.history.push(
      `/substance-analysis/limit/${limit}/substance/${substance.id}`
    );
  }

  render() {
    const { data } = this.state;
    return (
      <PrimaryContentContainer className={styles.primaryContentContainer}>
        {data && (
          <React.Fragment>
            <PrimaryContentHeader>
              <Heading.h2>
                {data.name} ({data.code})
              </Heading.h2>
              <PrimaryContentHeaderIcon
                name="times-light"
                onClick={this.handleClose.bind(this)}
              />
            </PrimaryContentHeader>
            {this.renderBody()}
          </React.Fragment>
        )}
      </PrimaryContentContainer>
    );
  }

  renderBody() {
    const { t } = this.props;
    const { data } = this.state;
    if (!data) return null;
    const totalMeasured = data.nbSubstancesTotal - data.nbSubstancesNoData;

    return (
      <PrimaryContentBody>
        <Paragraph>
          {t('generalStatus.totalMeasured')}: {totalMeasured}
          <br />
          {t('generalStatus.nbSubstancesNoEvaluationDrinkingWaterLimit')}:{' '}
          {data.nbSubstancesNoEvaluationDrinkingWaterLimit}
          <br />
          {t('generalStatus.nbSubstancesNoEvaluationEnvironmentalLimit')}:{' '}
          {data.nbSubstancesNoEvaluationEnvironmentalLimit}
        </Paragraph>

        <Heading.h3>{t('generalStatus.titleDashboard')}</Heading.h3>

        <Flex>
          <div className="graph">
            <div className="title">
              {t('generalStatus.titleDrinkingWaterLimitExeedance')}
            </div>
            <Pauge
              value={data.nbSubstancesExceedingDrinkingWaterLimit}
              total={
                totalMeasured - data.nbSubstancesNoEvaluationDrinkingWaterLimit
              }
              valueFill="#eba900"
              valueEntity={t('generalStatus.graphValue')}
            />
            <div style={{ textAlign: 'center' }}>
              {data.substancesExceedingDrinkingWaterLimit &&
              data.substancesExceedingDrinkingWaterLimit.length > 0
                ? data.substancesExceedingDrinkingWaterLimit.map(
                    (substance: Substance) => {
                      return (
                        <div
                          onClick={() => {
                            this.goToSubstanceDetails(
                              substance,
                              'drinking_water_limit_single_value'
                            );
                          }}
                          style={{ marginBottom: '5px', cursor: 'pointer' }}
                          key={substance.id}
                        >
                          {substance.name}
                        </div>
                      );
                    }
                  )
                : ''}
            </div>
          </div>
          <div className="graph">
            <div className="title">
              {t('generalStatus.titleEnvironmentalLimitExeedance')}
            </div>
            <Pauge
              value={data.nbSubstancesExceedingEnvironmentalLimit}
              total={
                totalMeasured - data.nbSubstancesNoEvaluationEnvironmentalLimit
              }
              valueFill="#ed6200"
              valueEntity={t('generalStatus.graphValue')}
            />
            <div style={{ textAlign: 'center' }}>
              {data.substancesExceedingEnvironmentalLimit &&
              data.substancesExceedingEnvironmentalLimit.length > 0
                ? data.substancesExceedingEnvironmentalLimit.map(
                    (substance: Substance) => {
                      return (
                        <div
                          onClick={() => {
                            this.goToSubstanceDetails(
                              substance,
                              'environmental_limit_single_value'
                            );
                          }}
                          style={{ marginBottom: '5px', cursor: 'pointer' }}
                          key={substance.id}
                        >
                          {substance.name}
                        </div>
                      );
                    }
                  )
                : ''}
            </div>
          </div>
        </Flex>
      </PrimaryContentBody>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: Props) => {
  return {
    activeCatchment: getActiveCatchment(state),
    activeSource: getActiveSource(state),
    activePeriod: getActivePeriod(state),
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps)(GeneralStatusContainer))
);
