import React from 'react';
import styles from './accordion-section.module.scss';
import Icon from '../../components-marvin/icon';

interface Props {
  children: React.ReactNode;
  isOpen?: boolean;
  label: string;
  onClick: () => void;
}

const AccordionSection = ({
  children,
  isOpen = false,
  label,
  onClick,
}: Props) => {
  return (
    <div className={styles.accordionSection}>
      <div onClick={onClick} className={styles.header}>
        <Icon
          name={isOpen ? 'caret-down' : 'caret-right'}
          size={16}
          className={styles.icon}
        />
        {label}
      </div>
      {isOpen && <div className={styles.body}>{children}</div>}
    </div>
  );
};

export default AccordionSection;
