import React, { useState, useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import { CatchmentLayer } from '../../types';
import RadioButton from '../../components-marvin/radio-button';
import Tooltip from '../../components-marvin/tooltip';
import Icon from '../../components-marvin/icon';
import { useLocation } from 'react-router';
import { find, uniq } from 'lodash-es';
import { useTranslation } from 'react-i18next';

interface Props {
  layers: CatchmentLayer[];
  activeLayer: number | null;
  onToggleLayer: (id: number) => void;
}

export default function ParcelLayers(props: Props) {
  const location = useLocation();

  const { layers, onToggleLayer } = props;

  const filteredLayers = useMemo(() => {
    return layers.filter(layer => {
      if (layer.bmpMap && location.pathname.includes('measures')) {
        return true;
      } else if (!location.pathname.includes('measures')) {
        return true;
      }
      return false;
    });
  }, [location, layers]);

  const layerPrefixes: string[] = useMemo(() => {
    const prefixes = filteredLayers.map(layer => layer.wmsLayerPrefix);
    return uniq(prefixes);
  }, [filteredLayers]);

  useEffect(() => {
    const activeLayer = filteredLayers.find(layer => layer.isActive);
    if (activeLayer === null) onToggleLayer(filteredLayers[0].id);
  }, [filteredLayers, onToggleLayer]);

  return render();

  function render() {
    return (
      <div>
        <LayerNone onClick={handleClick} activeLayer={props.activeLayer} />

        {layerPrefixes.map(layerPrefix => {
          const variations = layers.filter(
            layer => layer.wmsLayerPrefix === layerPrefix
          );
          if (variations.length === 1) {
            return (
              <LayerWithoutVariations
                key={layerPrefix}
                layer={variations[0]}
                activeLayer={props.activeLayer}
                onClick={handleClick}
              />
            );
          } else if (variations.length > 1) {
            return (
              <LayerWithVariations
                key={layerPrefix}
                layers={variations}
                activeLayer={props.activeLayer}
                onClick={handleClick}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  }

  function handleClick(id: number) {
    props.onToggleLayer(id);
  }
}

function LayerNone(props: {
  activeLayer: number | null;
  onClick: (id: number) => void;
}) {
  const { t } = useTranslation();
  const { activeLayer } = props;
  const isActive = useMemo(() => activeLayer === -1, [activeLayer]);

  return render();
  function render() {
    return (
      <div className={styles.layerItem} key="layer-none">
        <span className={styles.layerItemName}>{t('map.noExtraLayer')}</span>
        <RadioButton
          onClick={() => {
            props.onClick(-1);
          }}
          active={isActive}
        />
      </div>
    );
  }
}

function LayerWithoutVariations(props: {
  activeLayer: number | null;
  layer: CatchmentLayer;
  onClick: (id: number) => void;
}) {
  const { activeLayer, layer } = props;
  const isActive = useMemo(() => activeLayer === layer.id, [
    activeLayer,
    layer,
  ]);

  return render();

  function render() {
    return (
      <div className={styles.layerItem} key={`${layer.id}-${layer.variation}`}>
        <span className={styles.layerItemName}>
          {layer.namePrefix}
          &nbsp;
          {layer.source && (
            <Tooltip
              position="left"
              text={layer.source}
              secondaryText={layer.sourceUrl}
              delay={100}
            >
              <Icon
                name="info-circle"
                size={12}
                fill="#d4d4dd"
                className={styles.sourceInfo}
              />
            </Tooltip>
          )}
        </span>
        <RadioButton
          onClick={() => {
            props.onClick(layer.id);
          }}
          active={isActive}
        />
      </div>
    );
  }
}

function LayerWithVariations(props: {
  layers: CatchmentLayer[];
  activeLayer: number | null;
  onClick: (id: number) => void;
}) {
  const { activeLayer, layers } = props;
  const isActive = useMemo(() => {
    if (!activeLayer) return false;
    const layerIds = layers.map(l => l.id);
    return layerIds.includes(activeLayer);
  }, [activeLayer, layers]);

  const initialActiveVariation = (() => {
    const variation = layers.find(layer => layer.id === activeLayer);
    if (variation) return variation.variation;
    return layers[0].variation;
  })();
  const [activeVariation, setActiveVaration] = useState<string | undefined>(
    initialActiveVariation
  );

  function handleVariationChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setActiveVaration(event.target.value);
    const variationLayer = find(layers, { variation: event.target.value });
    if (isActive && variationLayer) {
      props.onClick(variationLayer.id);
    }
  }

  function handleLayerClick() {
    const variationLayer = find(layers, { variation: activeVariation });
    if (variationLayer) props.onClick(variationLayer.id);
  }

  return render();

  function render() {
    return (
      <div className={styles.layerItem}>
        <span className={styles.layerItemName}>
          {layers[0].namePrefix}
          &nbsp;
          {layers[0].source && (
            <Tooltip
              position="left"
              text={layers[0].source}
              secondaryText={layers[0].sourceUrl}
              delay={100}
            >
              <Icon
                name="info-circle"
                size={12}
                fill="#d4d4dd"
                className={styles.sourceInfo}
              />
            </Tooltip>
          )}
        </span>
        <select value={activeVariation} onChange={handleVariationChange}>
          {layers.map((layer: CatchmentLayer) => {
            return (
              <option key={layer.variation} value={layer.variation}>
                {layer.variation}
              </option>
            );
          })}
        </select>
        <RadioButton onClick={handleLayerClick} active={isActive} />
      </div>
    );
  }
}
