import React, { Component } from 'react';
import { noop, isNull } from 'lodash-es';
import {
  Map as LeafletMap,
  ScaleControl,
  TileLayer,
  WMSTileLayer,
  GeoJSON,
} from 'react-leaflet';
import ResizeObserver from 'resize-observer-polyfill'; // TODO: this can be cleaned up with the react-hooks pattern
import { BaseLayer, LeafletLayer } from '../types';
import {
  LeafletEvent,
  Layer,
  LatLngBoundsExpression,
  LeafletMouseEvent,
} from 'leaflet';

interface Props {
  zoom: number;
  maxZoom?: number;
  lat: number;
  lng: number;
  boundingbox: LatLngBoundsExpression | null;
  baselayers: BaseLayer[];
  overlayers: LeafletLayer[];
  geoJSONs: any[];
  overlayerOpacity?: number;
  showDraw: boolean;
  onGeoJSONClick: (layer: Layer) => void;
  onClick: (data: any) => void;
  onChange: (position: { lat: number; lng: number; zoom: number }) => void;
  onDrawChange: Function;
  style?: string;
}

interface State {
  selectedPolygon: any | null;
  selectedGeoJSONFeature: Layer | null;
}

class Map extends Component<Props, State> {
  leafletMapRef = React.createRef<LeafletMap>();

  static defaultProps = {
    lat: 51.00079308917475,
    lng: 4.498901367187501,
    zoom: 9,
    maxZoom: 18,
    style: '',
    baselayers: [],
    overlayers: [],
    geoJSONs: [],
    overlayerOpacity: 0.75,
    onClick: noop,
    onChange: noop,
    onDrawChange: noop,
    onGeoJSONClick: noop,
    showDraw: false,
  };

  state = {
    selectedPolygon: null,
    selectedGeoJSONFeature: null,
  };

  bubbleChanges(event: LeafletEvent) {
    const { lat, lng } = event.target.getCenter();
    const zoom = event.target.getZoom();
    this.props.onChange({ lat, lng, zoom });
  }

  componentDidMount() {
    this.watchResize();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.boundingbox !== prevProps.boundingbox) {
      if (this.props.boundingbox !== null) {
        this.leafletMapRef.current!.leafletElement.fitBounds(
          this.props.boundingbox
        );
      }
    }
  }

  watchResize() {
    const ro = new ResizeObserver(entries => {
      if (!isNull(this.leafletMapRef.current)) {
        this.leafletMapRef.current.leafletElement.invalidateSize();
      }
    });
    const leafletEl = document.querySelector('.leaflet-container');
    if (leafletEl) ro.observe(leafletEl);
  }

  handleDrawChange(event: any): void {
    this.setState({ selectedPolygon: event.target });
    this.props.onDrawChange(event.layer.toGeoJSON());
  }

  handleDrawEdit(event: any): void {
    event.layers.eachLayer((layer: any) => {
      this.props.onDrawChange(layer.toGeoJSON());
    });
  }

  handleDrawDelete(event: any): void {
    this.setState({ selectedPolygon: null });
    this.props.onDrawChange(null);
  }

  handleGeoJSONFeature(feature: any, layer: Layer) {
    layer.on({
      click: () => {
        if (this.state.selectedGeoJSONFeature) {
          // @ts-ignore
          this.state.selectedGeoJSONFeature!.setStyle({
            color: 'rgb(51, 136, 255)',
          });
        }

        this.setState({ selectedGeoJSONFeature: layer }, () => {
          // @ts-ignore
          this.state.selectedGeoJSONFeature.setStyle({ color: 'gold' });
        });

        this.props.onGeoJSONClick(layer);
      },
    });
  }

  getDrawingEnabled() {
    return isNull(this.state.selectedPolygon);
  }

  handleMapClick(event: LeafletMouseEvent) {
    const mapSize = this.leafletMapRef?.current?.leafletElement.getSize();
    const x = event.containerPoint.x;
    const y = event.containerPoint.y;
    const bbox = this.leafletMapRef?.current?.leafletElement
      .getBounds()
      .toBBoxString();

    const position = event.latlng;
    this.props.onClick({ x, y, mapSize, bbox, position });
  }

  render() {
    const { baselayers, overlayers, overlayerOpacity, geoJSONs } = this.props;

    // TODO: check if this might better be somewhere else then in render?
    //   maybe in an update hook like componentWillUpdate?
    //   update leaflet map maxZoom property
    if (this.leafletMapRef.current) {
      this.leafletMapRef.current.leafletElement.setMaxZoom(this.props.maxZoom!);
    }

    return (
      <div className="map-full-page">
        <LeafletMap
          // @ts-ignore: the LeafletMap type is not compatible with a ref assignment
          ref={this.leafletMapRef}
          className="leaflet-map"
          center={[this.props.lat, this.props.lng]}
          zoom={this.props.zoom}
          zoomControl={false}
          onclick={this.handleMapClick.bind(this)}
          onzoomend={this.bubbleChanges.bind(this)}
          onmoveend={this.bubbleChanges.bind(this)}
          //   style={this.props.style}
        >
          {baselayers.map((baselayer, i) => {
            return (
              <TileLayer
                key={`baselayer-${i}`}
                attribution={baselayer.attribution}
                url={baselayer.url}
              />
            );
          })}

          {overlayers.map((overlayer, i) => {
            return (
              <WMSTileLayer
                key={`overlayer-${i}`}
                url={overlayer.url}
                layers={overlayer.layers}
                transparent={true}
                format={'image/png'}
                styles={overlayer.style}
                opacity={overlayerOpacity}
                minZoom={overlayer.minZoom}
                maxZoom={overlayer.maxZoom}
                {...{ ...(overlayer.buffer && { buffer: overlayer.buffer }) }}
              />
            );
          })}

          {geoJSONs.map((geoJSON, i) => {
            return (
              <GeoJSON
                key={new Date().getTime()}
                data={geoJSON}
                onEachFeature={this.handleGeoJSONFeature.bind(this)}
              />
            );
          })}

          <ScaleControl
            position="bottomright"
            metric={true}
            imperial={false}
            updateWhenIdle={true}
          />
          {this.props.children}
          {/* {this.renderLeafletChildren()} */}
        </LeafletMap>
        {/* {this.renderChildren()} */}
      </div>
    );
  }

  renderLeafletChildren() {
    const children: any = React.Children.map(
      this.props.children,
      (child, index) => {
        if ((child as any).type.name === 'LeafletChild') {
          return React.cloneElement(child as any);
        }
      }
    );

    return children;
  }

  renderChildren() {
    const children: any = React.Children.map(
      this.props.children,
      (child, index) => {
        if ((child as any).type.name !== 'LeafletChildren') {
          // @ts-ignore
          // return withLeaflet(child);
          // const clone = React.cloneElement(withLeaflet(child) as any);
          return React.cloneElement(child as any);
        }
      }
    );

    return children;
  }
}

export const LeafletChildren = (props: { children: any }) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Map;
