import React from 'react';
import styles from './styles.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  PrimaryContentContainer,
  PrimaryContentHeader,
  PrimaryContentBody,
} from '../../components/primary-content';
import { Heading } from '../../components/design-system';
// @ts-ignore: TODO: add custom module definition
import Flex, { FlexItem } from 'styled-flex-component';
import { useTranslation } from 'react-i18next';
import MeasuresInfo from '../../components/measures-info/measures-info.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../types';

interface OwnProps {}

type Props = OwnProps & RouteComponentProps;

const MeasuresContainer = (props: Props) => {
  const { t } = useTranslation();
  const selectedParcelUidn: number | null = useSelector((state: RootState) => {
    return state.map.selectedParcelUidn;
  });

  return (
    <PrimaryContentContainer className={styles.measuresContainer}>
      <PrimaryContentHeader>
        <Heading.h1>{t('measuresInfo.analysis')}</Heading.h1>
      </PrimaryContentHeader>
      <PrimaryContentBody>
        <Flex full column justifyBetween>
          <FlexItem>
            {selectedParcelUidn ? (
              <MeasuresInfo parcelId={selectedParcelUidn} />
            ) : (
              <div className={styles.info}>
                {t('measuresInfo.selectMeasure')}
              </div>
            )}
          </FlexItem>
        </Flex>
      </PrimaryContentBody>
    </PrimaryContentContainer>
  );
};

export default withRouter(MeasuresContainer);
