import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import { RootState, Catchment, Period, LanguageItem, Source } from '../types';
import * as catchmentActions from '../redux/catchments/actions';
import * as periodActions from '../redux/period/actions';
import * as preferenceActions from '../redux/preferences/actions';
import { languages } from '../config';
import CatchmentSelector from '../components/catchment-selector';
import PeriodSelector from '../components/period-selector/period-selector';
import LocationSearchBox from '../components/location-seach-box';
import { setMapPosition, setMapBoundingbox } from '../redux/map/actions';
import {
  getCatchments,
  getActiveCatchment,
  getActiveSource,
  getActiveDates,
  getActivePeriod,
  getActiveLanguage,
} from '../selectors';
import LanguageSelector from '../components/language-selector';
import { RouteComponentProps, withRouter } from 'react-router';

interface OwnProps {
  isLoading: boolean;
  activeLanguage: LanguageItem;
  catchments: Catchment[];
  activeCatchment: Catchment | undefined;
  activeSource: Source | undefined;
  activeDates: Date[];
  activePeriod: Period;
}

type Props = OwnProps &
  WithTranslation &
  DispatchProp &
  RouteComponentProps<{ locationId: any; limit: any; substanceId: any }>;

class HeaderContainer extends React.Component<Props, {}> {
  handleLanguageChange(language: LanguageItem) {
    this.props.dispatch(preferenceActions.setLanguage(language));
  }

  // TODO: define a searchresult type
  handleSearchLocationClick(searchResult: any) {
    const { lat, lng, boundingbox } = searchResult;
    this.props.dispatch(setMapPosition({ lat, lng, zoom: 12 }));
    this.props.dispatch(setMapBoundingbox(boundingbox));
  }

  handleCatchmentSelect(catchment: Catchment, source: Source) {
    this.props.dispatch(catchmentActions.activateCatchment(catchment, source));
    this.props.history.push(`/water-quality-locations`);
  }

  handlePeriodSelect(period: Period) {
    this.props.dispatch(periodActions.activatePeriod(period));
    this.props.history.push(`/water-quality-locations`);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="header-container">
        <div className="header-group">
          <div className="header-item header-item-logo">
            <img
              alt="logo"
              className="logo"
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
            />
          </div>
          <div className="header-item">
            {!this.props.isLoading &&
              this.props.activeCatchment &&
              this.props.activeSource && (
                <CatchmentSelector
                  catchments={this.props.catchments}
                  activeCatchment={this.props.activeCatchment}
                  activeSource={this.props.activeSource}
                  onClick={this.handleCatchmentSelect.bind(this)}
                />
              )}
          </div>
          <div className="header-item">
            <PeriodSelector
              selectedPeriod={this.props.activePeriod}
              activeDates={this.props.activeDates}
              onClick={this.handlePeriodSelect.bind(this)}
            />
          </div>
        </div>

        <div className="header-group">
          <div className="header-item">
            <LocationSearchBox
              // TODO: Rethink on item click event, maybe pass items as children component?
              // ... <LocationSearchBox searchItemComponent={SeachItemComponent} />
              placeholder={t('global.search') as string}
              onSearchResultClick={this.handleSearchLocationClick.bind(this)}
            />
          </div>

          <LanguageSelector
            activeLanguage={this.props.activeLanguage}
            languages={languages}
            onClick={this.handleLanguageChange.bind(this)}
          />

          {/* <HeaderItem>
            <HeaderItemVisible>
              <Icon name="bell" className="header-icon" />
            </HeaderItemVisible>
          </HeaderItem>
          <div className="header-item">
            <Icon name="user-circle" className="header-icon" />
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isLoading: state.catchments.isPending,
    activeLanguage: getActiveLanguage(state),
    catchments: getCatchments(state),
    activeCatchment: getActiveCatchment(state),
    activeSource: getActiveSource(state),
    activeDates: getActiveDates(state),
    activePeriod: getActivePeriod(state),
  };
};

export default withTranslation()(
  withRouter(connect(mapStateToProps)(HeaderContainer))
);
