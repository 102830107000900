import axios from 'axios';
import { SourceType, Crop, Period } from '../types';
import * as axiosUtils from './../utils/axios.utils';

export async function getSubstances(
  catchmentId: number,
  source: SourceType,
  period: Period
): Promise<Crop[]> {
  const headers = axiosUtils.commonHeaders();
  const response = await axios.get(
    `/api/catchments/${catchmentId}/substances`,
    {
      headers,
      params: {
        source,
        startDateTime: period.from,
        endDateTime: period.to,
      },
    }
  );
  return response.data;
}

export async function getSubstancesAll(
  catchmentId: number,
  source: SourceType,
  period: Period
): Promise<{
  parameterList: [];
  pesticideList: Crop[];
  pesticideSumList: Crop[];
  nitrateList: [];
}> {
  const url = `/api/catchments/${catchmentId}/substancesAll`;
  const config = {
    headers: axiosUtils.commonHeaders(),
    params: {
      source,
      startDateTime: period.from,
      endDateTime: period.to,
    },
  };
  const response = await axios.get(url, config);
  return response.data;
}
